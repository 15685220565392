"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientConfigurationTab = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var get_configuration_1 = require("../../../../api/calls/configuration/get_configuration");
var save_configuration_1 = require("../../../../api/calls/configuration/save_configuration");
var textarea_1 = require("../../../../controls/textarea/textarea");
var update_nested_property_1 = require("../../../../utils/update_nested_property");
var ClientConfigurationTab = function (props) {
    var _a, _b, _c;
    var _d = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _e = params.client_entity_id, client_entity_id = _e === void 0 ? "" : _e;
    var _f = (0, react_1.useState)(false), isBusy = _f[0], setIsBusy = _f[1];
    var _g = (0, react_1.useState)({
        home_youtube_url: "",
        disclaimer_md_text: "",
        privacy_policy_md_text: "",
        terms_conditions_md_text: "",
        auction_rules_conditions_md_text: "",
        contacts: {
            cell_number: "",
            email: "",
            whatsapp: "",
        },
    }), configuration = _g[0], setConfiguration = _g[1];
    var onConfigChange = function (key, value) {
        var updatedConfig = (0, update_nested_property_1.updateNestedProperty)(configuration, key, value);
        setConfiguration(updatedConfig);
    };
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            (0, get_configuration_1.get_configuration)({
                client_entity_id: client_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    if (response.data.content) {
                        setConfiguration(response.data.content);
                    }
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Failed to get configuration for client!", messages: [response.data.message] }));
                }
            });
        }
    }, []);
    // const validate = () => {
    //   const messages: any = [];
    //   if (!client.name) {
    //     messages.push("client name is required.");
    //   }
    //   if (messages && messages.length > 0) {
    //     dispatch(setMessageModal({ title: "Validation failed!", messages: messages }));
    //     return false;
    //   } else {
    //     return true;
    //   }
    // };
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4 " },
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save Configuration", backgroundColor: "bg-green-500", size: "small", onClick: function () {
                        (0, save_configuration_1.save_configuration)({
                            client_entity_id: client_entity_id,
                            home_youtube_url: configuration.home_youtube_url,
                            disclaimer_md_text: configuration.disclaimer_md_text,
                            privacy_policy_md_text: configuration.privacy_policy_md_text,
                            terms_conditions_md_text: configuration.terms_conditions_md_text,
                            auction_rules_conditions_md_text: configuration.auction_rules_conditions_md_text,
                            contacts: configuration.contacts,
                        }).then(function (response) {
                            if (response.data.success) {
                                dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Configuration saved successfully!"] }));
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Failed to save configuration!", messages: [response.data.message] }));
                            }
                        });
                    } })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "text-lg font-bold" }, "Terms"),
                react_1.default.createElement("div", { className: "grid grid-cols-3 gap-4" },
                    react_1.default.createElement(textarea_1.TextAreaControl, { label: "App Disclaimer", lines: 5, value: configuration.disclaimer_md_text, onChange: function (v) { return onConfigChange("disclaimer_md_text", v); } }),
                    react_1.default.createElement(textarea_1.TextAreaControl, { label: "App Privacy Policy", lines: 5, value: configuration.privacy_policy_md_text, onChange: function (v) { return onConfigChange("privacy_policy_md_text", v); } }),
                    react_1.default.createElement(textarea_1.TextAreaControl, { label: "App Terms & Conditions", lines: 5, value: configuration.terms_conditions_md_text, onChange: function (v) { return onConfigChange("terms_conditions_md_text", v); } }),
                    react_1.default.createElement(textarea_1.TextAreaControl, { label: "Auction Rules and Conditions", lines: 5, value: configuration.auction_rules_conditions_md_text, onChange: function (v) { return onConfigChange("auction_rules_conditions_md_text", v); } }))),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "text-lg font-bold" }, "Youtube"),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "App Home Screen Youtube URL", value: configuration.home_youtube_url, onChange: function (v) { return onConfigChange("home_youtube_url", v); } })),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "text-lg font-bold" }, "Contacts"),
                react_1.default.createElement("div", { className: "grid grid-cols-3 gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cell number", value: (_a = configuration.contacts) === null || _a === void 0 ? void 0 : _a.cell_number, type: "text", onChange: function (v) { return onConfigChange("contacts.cell_number", v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address", value: (_b = configuration.contacts) === null || _b === void 0 ? void 0 : _b.email, type: "text", onChange: function (v) { return onConfigChange("contacts.email", v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Whatsapp number", value: (_c = configuration.contacts) === null || _c === void 0 ? void 0 : _c.whatsapp, type: "text", onChange: function (v) { return onConfigChange("contacts.whatsapp", v); } }))))));
};
exports.ClientConfigurationTab = ClientConfigurationTab;
