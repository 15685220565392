"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManualAdminScreen = void 0;
var react_1 = __importStar(require("react"));
var registered_bidders_1 = require("../../common/registered_bidders");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var manual_start_1 = require("../../../../../api/calls/auction/v3/manual_start");
var manual_end_1 = require("../../../../../api/calls/auction/v3/manual_end");
var main_1 = require("../../../../../reducers/slices/main");
var lots_for_auction_1 = require("../common/lots_for_auction");
var previous_current_next_1 = require("./previous_current_next");
var socket_1 = require("../../../../../socket");
var connection_status_1 = require("../../common/connection_status");
var floor_vendor_bidding_1 = require("./floor_vendor_bidding");
var bids_for_lot_1 = require("../common/bids_for_lot");
var get_auction_1 = require("../../../../../api/calls/auction/v3/get_auction");
var ManualAdminScreen = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }), mainAuction = _a.mainAuction, socketStatus = _a.socketStatus;
    var _b = (0, react_1.useState)(null), auction = _b[0], setAuction = _b[1];
    var _c = (0, react_1.useState)(null), currentLot = _c[0], setCurrentLot = _c[1];
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            socket_1.socket.emit("joinClientPipe", selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
            socket_1.socket.on("connect", function () {
                socket_1.socket.emit("joinClientPipe", selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
            });
        }
        else {
            navigate("/console/auctions");
            // console
        }
    }, [selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id]);
    (0, react_1.useEffect)(function () {
        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
            socket_1.socket.emit("joinAuctionPipe", mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
            socket_1.socket.on("connect", function () {
                socket_1.socket.emit("joinAuctionPipe", mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
            });
            socket_1.socket.on("auctionManualStart", function (data) {
                console.log("auctionManualStart", data);
                var entity_id = data.entity_id, status = data.status;
                // cause a refresh
                setAuction(function (prev) {
                    return __assign(__assign({}, prev), { status: status });
                });
            });
            socket_1.socket.on("auctionManualStop", function (data) {
                console.log("auctionManualStop", data);
                var entity_id = data.entity_id, status = data.status;
                // cause a refresh
                setAuction(function (prev) {
                    return __assign(__assign({}, prev), { status: status });
                });
            });
            // setAuction(mainAuction);
            (0, get_auction_1.get_auction)({
                entity_id: mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    setAuction(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Error",
                        messages: [response.data.message],
                    }));
                }
            });
        }
        return function () {
            // socket.off("connect");
            socket_1.socket.off("auctionManualStart");
            socket_1.socket.off("auctionManualStop");
        };
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    return (react_1.default.createElement("div", { className: "flex flex-col p-4 gap-4" },
        react_1.default.createElement("div", { className: "flex gap-4" },
            react_1.default.createElement("div", { className: "flex-1 flex gap-4" }, (auction === null || auction === void 0 ? void 0 : auction.status) == "In-Progress" ? (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Stop Auction", backgroundColor: "bg-red-500", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "End Auction?",
                        messages: ["Are you sure you want to end the auction?"],
                        buttons: "yesNo",
                        onConfirm: function () {
                            (0, manual_end_1.manual_end)({
                                auction_entity_id: auction.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    dispatch((0, main_1.setMessageModal)(null));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Error",
                                        messages: [response.data.message],
                                    }));
                                }
                            });
                        },
                    }));
                } })) : (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Go Live", backgroundColor: "bg-green-500", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Go Live?",
                        messages: ["Are you sure you want to go live?"],
                        buttons: "yesNo",
                        onConfirm: function () {
                            console.log("Going live", auction);
                            (0, manual_start_1.manual_start)({
                                auction_entity_id: auction.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    dispatch((0, main_1.setMessageModal)(null));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Error",
                                        messages: [response.data.message],
                                    }));
                                }
                            });
                        },
                    }));
                } }))),
            react_1.default.createElement(connection_status_1.ConnectionState, null)),
        (auction === null || auction === void 0 ? void 0 : auction.status) == "In-Progress" ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "flex justify-between" },
                react_1.default.createElement("div", { className: "font-bold text-2xl" }, auction.title),
                react_1.default.createElement("div", { className: "font-bold text-2xl" }, auction.status)),
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(previous_current_next_1.AdminPreviousCurrentNext, { currentLotChanged: function (lot) {
                                setCurrentLot(lot);
                            } })),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                            react_1.default.createElement(floor_vendor_bidding_1.FloorVendorBidding, { lot_entity_id: currentLot === null || currentLot === void 0 ? void 0 : currentLot.entity_id }),
                            react_1.default.createElement(bids_for_lot_1.BidsForLot, { lot_entity_id: currentLot === null || currentLot === void 0 ? void 0 : currentLot.entity_id })),
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(lots_for_auction_1.LotsForAuction, { currentLot: currentLot })))),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(registered_bidders_1.RegisteredBiddersComponent, null))))) : (react_1.default.createElement(react_1.default.Fragment, null, "This auction is not in progress"))));
};
exports.ManualAdminScreen = ManualAdminScreen;
