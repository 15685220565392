"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regenerate_paddle_number = void 0;
var api_1 = require("../../../api");
var regenerate_paddle_number = function (req) {
    return (0, api_1.apiPatch)({
        endpoint: "v3/bidder/regenerate_paddle_number",
        data: req,
    });
};
exports.regenerate_paddle_number = regenerate_paddle_number;
