"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.upload_lots_csv = void 0;
var api_1 = require("../../../api");
var upload_lots_csv = function (request) {
    console.log(request);
    return (0, api_1.apiPost)({
        endpoint: "v3/lot/upload_csv",
        data: request,
    });
};
exports.upload_lots_csv = upload_lots_csv;
