"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auction_remaining_time = void 0;
var api_1 = require("../../../api");
var auction_remaining_time = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/auction/remaining_time",
        query: req,
    });
};
exports.auction_remaining_time = auction_remaining_time;
