"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_user = void 0;
var api_1 = require("../../../api");
var delete_user = function (request) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/user/delete",
        data: request,
    });
};
exports.delete_user = delete_user;
