"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.admin_autogen_user_password = void 0;
var api_1 = require("../../../api");
var admin_autogen_user_password = function (request) {
    // const {
    //   userState: {
    //     user: { token },
    //   },
    // } = redux_store.getState();
    return (0, api_1.apiPatch)({
        endpoint: "v3/user/admin_autogen_user_password",
        data: request,
        // authorization: token,
    });
};
exports.admin_autogen_user_password = admin_autogen_user_password;
