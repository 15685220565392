"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.admins_for_client = void 0;
var api_1 = require("../../api");
var admins_for_client = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/admin/admins_for_client",
        query: request,
    });
};
exports.admins_for_client = admins_for_client;
