"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_all_client_users = void 0;
var api_1 = require("../../api");
var get_all_client_users = function (client_id) {
    return (0, api_1.apiGet)({
        endpoint: "v3/user/users_for_client",
        query: {
            client_entity_id: client_id,
        },
    });
};
exports.get_all_client_users = get_all_client_users;
