"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientDetailsTab = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var get_client_1 = require("../../../../api/calls/client/get_client");
var update_client_1 = require("../../../../api/calls/client/update_client");
var create_client_1 = require("../../../../api/calls/client/create_client");
var ClientDetailsTab = function (props) {
    var onSave = props.onSave;
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _a = params.client_entity_id, client_entity_id = _a === void 0 ? "" : _a;
    var _b = (0, react_1.useState)(false), isBusy = _b[0], setIsBusy = _b[1];
    var _c = (0, react_1.useState)({
        name: "",
        entity_id: "",
        token: "",
        bid_increments: [],
    }), client = _c[0], setClient = _c[1];
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            (0, get_client_1.get_client)({
                entity_id: client_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    setClient(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Failed to get client!", messages: [response.data.message] }));
                }
            });
        }
    }, []);
    var validate = function () {
        var messages = [];
        if (!client.name) {
            messages.push("client name is required.");
        }
        if (messages && messages.length > 0) {
            dispatch((0, main_1.setMessageModal)({ title: "Validation failed!", messages: messages }));
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row gap-4" }, (client === null || client === void 0 ? void 0 : client.entity_id) ? (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update Client Details", backgroundColor: "bg-green-500", loading: isBusy, size: "small", onClick: function () {
                if (validate()) {
                    setIsBusy(true);
                    (0, update_client_1.update_client)({
                        entity_id: client.entity_id,
                        name: client.name,
                        bid_increments: client.bid_increments,
                    }).then(function (response) {
                        setIsBusy(false);
                        if (response.data.success) {
                            setClient(response.data.content);
                            dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Client updated successfully!"] }));
                            onSave(response.data.content);
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({ title: "Failed to update client!", messages: [response.data.message] }));
                        }
                    });
                }
            } })) : (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create New Client", loading: isBusy, backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                if (validate()) {
                    setIsBusy(true);
                    (0, create_client_1.create_client)({
                        name: client.name,
                    }).then(function (response) {
                        setIsBusy(false);
                        if (response.data.success) {
                            setClient(response.data.content);
                            dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Client created successfully!"] }));
                            onSave(response.data.content);
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({ title: "Failed to create client!", messages: [response.data.message] }));
                        }
                    });
                }
            } }))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4 w-96" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Client Name", value: client.name, onChange: function (v) {
                        setClient(__assign(__assign({}, client), { name: v }));
                    } }))),
        (client === null || client === void 0 ? void 0 : client.token) && (react_1.default.createElement("div", { className: "" },
            react_1.default.createElement("div", { className: "text-lg font-bold" }, "Client Token:"),
            react_1.default.createElement("div", { className: "break-all" }, client.token)))));
};
exports.ClientDetailsTab = ClientDetailsTab;
