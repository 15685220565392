"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountdownComponent = void 0;
var react_1 = __importStar(require("react"));
var remaining_time_1 = require("../../../../api/calls/auction/v3/remaining_time");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var CountdownComponent = function (props) {
    var auction_id = props.auction_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    // countdown format = 00:00:00:00
    var _a = (0, react_1.useState)("00:00:00:00"), remainingTime = _a[0], setRemainingTime = _a[1];
    var interval = react_1.default.useRef(null);
    (0, react_1.useEffect)(function () {
        // document.addEventListener("visibilitychange", () => {
        //   if (document.hidden) {
        //     console.log("hidden means user is gone");
        //   } else {
        //     console.log("visible means user is back");
        //     getRemainingTime(auction_id);
        //   }
        // });
        // window.addEventListener("blur", () => {
        //   console.log("blur means user is away");
        // });
    }, []);
    function getRemainingTime() {
        // get remaining time
        (0, remaining_time_1.auction_remaining_time)({
            auction_entity_id: auction_id,
        }).then(function (response) {
            if (response.data.success) {
                updateRemainingTime(response.data.content.remaining_time);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Failed to retrieve remaining time for auction!", messages: [response.data.message] }));
            }
        });
    }
    (0, react_1.useEffect)(function () {
        if (auction_id) {
            getRemainingTime();
        }
        window.addEventListener("focus", getRemainingTime);
        return function () {
            window.removeEventListener("focus", getRemainingTime);
        };
        // Update the count down every 1 second
    }, [auction_id]);
    function updateRemainingTime(rt) {
        if (rt) {
            var _a = rt.split(":").map(Number), days = _a[0], hours = _a[1], minutes = _a[2], seconds = _a[3];
            var remainingSeconds_1 = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
            if (interval.current) {
                clearInterval(interval.current);
            }
            // set timer
            interval.current = setInterval(function () {
                // Check if the countdown is complete
                if (remainingSeconds_1 <= 0) {
                    clearInterval(interval.current);
                    console.log("Countdown complete!");
                }
                else {
                    // Decrement remaining seconds
                    remainingSeconds_1--;
                    // Calculate remaining time components
                    var remainingDays = Math.floor(remainingSeconds_1 / (24 * 60 * 60));
                    var remainingHours = Math.floor((remainingSeconds_1 % (24 * 60 * 60)) / (60 * 60));
                    var remainingMinutes = Math.floor((remainingSeconds_1 % (60 * 60)) / 60);
                    var remainingSecondsOutput = remainingSeconds_1 % 60;
                    // Display the remaining time
                    setRemainingTime("".concat(remainingDays.toString().padStart(2, "0"), ":").concat(remainingHours.toString().padStart(2, "0"), ":").concat(remainingMinutes
                        .toString()
                        .padStart(2, "0"), ":").concat(remainingSecondsOutput.toString().padStart(2, "0")));
                }
            }, 1000); // Update every second
        }
    }
    return (react_1.default.createElement("div", { className: "flex flex-col items-center" },
        react_1.default.createElement("div", { className: "text-xl font-bold" }, remainingTime),
        react_1.default.createElement("div", { className: "text-sm" }, "Time Remaining")));
};
exports.CountdownComponent = CountdownComponent;
