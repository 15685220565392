"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create_client = void 0;
var api_1 = require("../../api");
var create_client = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/client/create",
        data: req,
    });
};
exports.create_client = create_client;
