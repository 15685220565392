"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_all_lots = void 0;
var api_1 = require("../../../api");
var delete_all_lots = function (req) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/lot/delete_all",
        // data: formData,
        data: req,
    });
};
exports.delete_all_lots = delete_all_lots;
