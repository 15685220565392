"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsEditing = exports.setSelectedAuction = exports.setAuctions = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var slice = (0, toolkit_1.createSlice)({
    name: "auction",
    initialState: {
        auctions: [],
        isEditing: false,
        selectedAuction: {},
    },
    reducers: {
        setIsEditing: function (state, action) {
            state.isEditing = action.payload;
        },
        setAuctions: function (state, action) {
            state.auctions = action.payload;
        },
        setSelectedAuction: function (state, action) {
            state.selectedAuction = action.payload;
        },
    },
});
exports.setAuctions = (_a = slice.actions, _a.setAuctions), exports.setSelectedAuction = _a.setSelectedAuction, exports.setIsEditing = _a.setIsEditing;
exports.default = slice.reducer;
