"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotsScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var lot_1 = require("../../../reducers/slices/lot");
var lots_for_auction_1 = require("../../../api/calls/lot/v3/lots_for_auction");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var uploadCSV_1 = require("./uploadCSV");
var lot_2 = require("./lot");
var update_lots_order_1 = require("../../../api/calls/lot/v3/update_lots_order");
var get_csv_template_1 = require("../../../api/calls/lot/v3/get_csv_template");
var delete_all_1 = require("../../../api/calls/lot/v3/delete_all");
var delete_lot_1 = require("../../../api/calls/lot/v3/delete_lot");
var scroll_1 = require("../../../reducers/slices/scroll");
var LotsScreen = function (props) {
    var _a;
    var scrollRef = props.scrollRef;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.lotState; }), lots = _b.lots, selectedLot = _b.selectedLot;
    var modal = (0, react_redux_1.useSelector)(function (state) { return state.mainState; }).modal;
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var preserved_scroll = (0, react_redux_1.useSelector)(function (state) { return state.scrollState; }).preserved_scroll;
    var _c = (0, react_1.useState)([]), filteredData = _c[0], setFilteredData = _c[1];
    (0, react_1.useEffect)(function () {
        console.log("preserved_scroll", preserved_scroll);
        return function () {
            var _a;
            // lots unmounted, lets clear the scroll position
            console.log("lots unmounted");
            dispatch((0, scroll_1.setPreservedScroll)(__assign(__assign({}, preserved_scroll), (_a = {}, _a[pathname] = null, _a))));
        };
    }, []);
    (0, react_1.useEffect)(function () {
        getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
    }, [mainAuction]);
    if (!mainAuction && (lots === null || lots === void 0 ? void 0 : lots.length) > 0) {
        dispatch((0, lot_1.setLots)([]));
    }
    var getLots = function (selectedID) {
        if (selectedID) {
            (0, lots_for_auction_1.lots_for_auction)({
                auction_entity_id: selectedID,
            }).then(function (response) {
                if (response.data.success) {
                    dispatch((0, lot_1.setLots)(response.data.content));
                }
                else {
                    console.log("response.data.message", response.data.message);
                }
            });
        }
        // }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4 w-full" },
            react_1.default.createElement(react_router_dom_1.Routes, null,
                react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement("div", { className: "flex flex-col space-y-4 overflow-visible" },
                        react_1.default.createElement("div", { className: "text-2xl font-bold" },
                            "Lots for ", (_a = mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.title) !== null && _a !== void 0 ? _a : "[select an auction]"),
                        react_1.default.createElement("div", { className: "flex justify-left gap-4" },
                            react_1.default.createElement("div", { className: "w-40" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Lot", backgroundColor: "bg-green-500", size: "small", onClick: function () {
                                        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
                                            dispatch((0, lot_1.setIsEditing)(false));
                                            dispatch((0, lot_1.setSelectedLot)({
                                                //Don's set anything else on create unless necessary.
                                                auction_id: mainAuction.entity_id,
                                                type: mainAuction.type,
                                            }));
                                            navigate("".concat(pathname, "/lot"));
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Select an auction",
                                                messages: ["Please select an auction (top-left) before trying to create a lot."],
                                                // buttons: "yesNo",
                                                onConfirm: function () {
                                                    //
                                                },
                                            }));
                                        }
                                    } })),
                            react_1.default.createElement("div", { className: "w-40" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Refresh List", backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                                        getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                                    } })),
                            react_1.default.createElement("div", { className: "w-52" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save Lot Numbers", size: "small", backgroundColor: "bg-orange-500", onClick: function () {
                                        (0, update_lots_order_1.update_lot_numbers)({
                                            order: lots.map(function (x, i) {
                                                return { lot_entity_id: x.entity_id, lot_number: i + 1 };
                                            }),
                                        }).then(function (response) {
                                            if (response.data.success) {
                                                dispatch((0, main_1.setMessageModal)({ title: "Success", messages: ["Lot numbers saved successfully!"] }));
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({ title: "Error saving lot numbers", messages: [response.data.message] }));
                                            }
                                        });
                                    } })),
                            react_1.default.createElement("div", { className: "w-44" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Upload Lots CSV", backgroundColor: "bg-bluish", size: "small", onClick: function () {
                                        //  Papa.parse("https://doshx.com/lot.csv", {
                                        if (!(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id)) {
                                            dispatch((0, main_1.setMessageModal)({ title: "Select Auction", messages: ["Please select an auction!"] }));
                                        }
                                        else {
                                            dispatch((0, main_1.setContentModal)({
                                                title: "Import Lots CSV",
                                                content: (react_1.default.createElement(uploadCSV_1.UploadCSVWidget, { onSave: function () {
                                                        getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                                                        // dispatch(setMessageModal(null));
                                                        dispatch((0, main_1.setMessageModal)({ title: "Success", messages: ["Lots uploaded successfully!"] }));
                                                        dispatch((0, main_1.setContentModal)(null));
                                                    } })),
                                                onClose: function () {
                                                    getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                                                },
                                            }));
                                        }
                                    } })),
                            react_1.default.createElement("div", { className: "w-48" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Get CSV Template", backgroundColor: "bg-cyan-500", size: "small", onClick: function () {
                                        (0, get_csv_template_1.get_csv_template)().then(function (response) {
                                            if (response.data.success) {
                                                var csv = response.data.content;
                                                var element = document.createElement("a");
                                                var file = new Blob([csv], { type: "text/csv" });
                                                element.style.display = "none";
                                                element.href = URL.createObjectURL(file);
                                                element.download = "lots_csv_template.csv";
                                                // document.body.appendChild(element); // Required for this to work in FireFox
                                                element.click();
                                                element.remove();
                                            }
                                        });
                                    } })),
                            react_1.default.createElement("div", { className: "w-48" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete All Lots", backgroundColor: "bg-red-500", size: "small", onClick: function () {
                                        if (!mainAuction.entity_id) {
                                            dispatch((0, main_1.setMessageModal)({ title: "Select Auction", messages: ["Please select an auction!"] }));
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Delete Lots?",
                                                messages: ["Are you sure you want to delete lots?"],
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, delete_all_1.delete_all_lots)({
                                                        auction_entity_id: mainAuction.entity_id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Error deleting lots", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        }
                                    } }))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TableControl, { showRowNumbers: true, showOrdering: true, onSearch: function (searchText) {
                                    // if (searchText) {
                                    //   console.log("searchText", searchText);
                                    //   const filteredLots = lots.filter((x) => {
                                    //     return x.title.toLowerCase().includes(searchText.toLowerCase());
                                    //   });
                                    //   setFilteredData(filteredLots);
                                    // } else {
                                    //   setFilteredData([]);
                                    // }
                                }, columns: [
                                    {
                                        label: "Title",
                                        displayKey: "title",
                                    },
                                    { label: "Type", displayKey: "type" },
                                    { label: "Status", displayKey: "status" },
                                    { label: "Vendor Bidding", displayKey: "", formatter: function (row) { return (row.vendor_bidding.enabled ? "Yes" : "No"); } },
                                ], data: filteredData && filteredData.length ? filteredData : lots, colors: {
                                    titleBackgroundColor: "bg-blue-600",
                                    headerBackgroundColor: "bg-bluish",
                                    headerTextColor: "text-white",
                                    ordering: {
                                        borderColor: "border-bluish",
                                    },
                                    hoverRowColor: "hover:bg-gray-200",
                                }, onRowOrderChange: function (orderedList) {
                                    dispatch((0, lot_1.setLots)(orderedList));
                                }, onRowClicked: function (row) {
                                    var _a;
                                    dispatch((0, scroll_1.setPreservedScroll)(__assign(__assign({}, preserved_scroll), (_a = {}, _a[pathname] = scrollRef.current.scrollTop, _a))));
                                    navigate("".concat(pathname, "/lot/").concat(row.entity_id));
                                }, rowActions: [
                                    {
                                        label: "Delete",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Are you sure?",
                                                messages: ["Are you sure that you want to delete this lot? (".concat(row.title, ")")],
                                                buttons: "delete",
                                                onConfirm: function () {
                                                    (0, delete_lot_1.delete_lot)({
                                                        entity_id: row.entity_id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                            getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                ] }))) }),
                react_1.default.createElement(react_router_dom_1.Route, { path: "/lot/:lot_entity_id?", element: react_1.default.createElement(lot_2.LotScreen, { scrollRef: scrollRef, onSuccess: function () {
                            getLots(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                        } }) })))));
};
exports.LotsScreen = LotsScreen;
