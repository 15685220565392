"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_file = void 0;
var api_1 = require("../../api");
var delete_file = function (request) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/file/delete",
        data: request,
    });
};
exports.delete_file = delete_file;
