"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_bidder = void 0;
var api_1 = require("../../../api");
var delete_bidder = function (req) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/bidder/delete",
        data: req,
    });
};
exports.delete_bidder = delete_bidder;
