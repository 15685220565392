"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientDetailsScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
// import { pdfjs, Document, Page } from "react-pdf";
// import moment from "moment";
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var colors_1 = require("../../../constants/colors");
var get_client_1 = require("../../../api/calls/client/get_client");
var details_1 = require("./tabs/details");
var bid_increments_1 = require("./tabs/bid_increments");
var delete_client_1 = require("../../../api/calls/client/delete_client");
var client_admins_1 = require("./tabs/client_admins");
var configuration_1 = require("./tabs/configuration");
var social_media_1 = require("./tabs/social_media");
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
var ClientDetailsScreen = function (props) {
    var refreshClientList = props.refreshClientList;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _a = params.client_entity_id, client_entity_id = _a === void 0 ? "" : _a;
    var _b = (0, react_1.useState)({
        name: "",
        entity_id: "",
    }), client = _b[0], setClient = _b[1];
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            (0, get_client_1.get_client)({
                entity_id: client_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    setClient(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Failed to get client!", messages: [response.data.message] }));
                }
            });
        }
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 w-full" },
        react_1.default.createElement("div", { className: "text-2xl font-bold" }, (client === null || client === void 0 ? void 0 : client.entity_id) ? "Details for ".concat(client === null || client === void 0 ? void 0 : client.name) : "Creating a New Client"),
        react_1.default.createElement("div", { className: "flex flex-row gap-4" }, (client === null || client === void 0 ? void 0 : client.entity_id) && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete This Client", backgroundColor: "bg-red-500", size: "small", onClick: function () {
                //sure?
                dispatch((0, main_1.setMessageModal)({
                    title: "Delete ".concat(client.name),
                    messages: [
                        "Are you sure you want to delete this client?",
                        "Note:",
                        "- This action is irreversible.",
                        "- All apps that are associated with this client will cease to work.",
                    ],
                    buttons: "delete",
                    onConfirm: function () {
                        (0, delete_client_1.delete_client)({
                            entity_id: client.entity_id,
                        }).then(function (response) {
                            if (response.data.success) {
                                setClient(response.data.content);
                                refreshClientList();
                                dispatch((0, main_1.setMessageModal)(null));
                                navigate("/console/clients");
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Failed to delete the client!", messages: [response.data.message] }));
                            }
                        });
                    },
                }));
            } }))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: colors_1.tabColors, tabs: [
                    {
                        title: "Details",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                            react_1.default.createElement(details_1.ClientDetailsTab, { onSave: function (client) {
                                    setClient(client);
                                    navigate("/console/clients/client/".concat(client.entity_id));
                                    refreshClientList();
                                } }))),
                    },
                    {
                        title: "Bid Increments",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                            react_1.default.createElement(bid_increments_1.BidIncrementsTab, null))),
                    },
                    {
                        title: "Configuration",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                            react_1.default.createElement(configuration_1.ClientConfigurationTab, null))),
                    },
                    {
                        title: "Social Media",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                            react_1.default.createElement(social_media_1.SocialMediaTab, { client_entity_id: client.entity_id }))),
                    },
                    {
                        title: "Admin Users",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                            react_1.default.createElement(client_admins_1.ClientAdminsTab, { client_entity_id: client.entity_id }))),
                    },
                ] }))));
};
exports.ClientDetailsScreen = ClientDetailsScreen;
