"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.place_bid_for_user = void 0;
var api_1 = require("../../api");
var place_bid_for_user = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v2/admin/place_bid_for_user",
        data: request,
    });
};
exports.place_bid_for_user = place_bid_for_user;
