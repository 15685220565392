"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.socket = void 0;
var socket_io_client_1 = require("socket.io-client");
// "undefined" means the URL will be computed from the `window.location` object
var URL = (_a = process.env.API_URL) !== null && _a !== void 0 ? _a : "";
exports.socket = (0, socket_io_client_1.io)(URL, {
    transports: ["websocket"],
    // autoConnect: false,
});
