"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUser = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var _devMode = process.env.NODE_ENV !== "production";
var slice = (0, toolkit_1.createSlice)({
    name: "user",
    initialState: {
        user: {
            admin: {
                email: _devMode ? "fred@doshex.com" : "",
                password: _devMode ? "1234" : "",
            },
        },
    },
    reducers: {
        setUser: function (state, action) {
            state.user = action.payload;
        },
    },
});
exports.setUser = slice.actions.setUser;
exports.default = slice.reducer;
