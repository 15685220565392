"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_lot = void 0;
var api_1 = require("../../../api");
var get_lot = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/lot/get",
        query: {
            entity_id: request.entity_id,
        },
    });
};
exports.get_lot = get_lot;
