"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_client = void 0;
var api_1 = require("../../api");
var update_client = function (req) {
    return (0, api_1.apiPut)({
        endpoint: "v3/client/update",
        data: req,
    });
};
exports.update_client = update_client;
