"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lots_for_auction = void 0;
var api_1 = require("../../../api");
var lots_for_auction = function (req) {
    console.log("lots_for_auction", req);
    return (0, api_1.apiGet)({
        endpoint: "v3/lot/lots_for_auction",
        query: req,
    });
};
exports.lots_for_auction = lots_for_auction;
