"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manual_previous_current_next = void 0;
var api_1 = require("../../../api");
var manual_previous_current_next = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/lot/manual_previous_current_next",
        // data: formData,
        data: req,
    });
};
exports.manual_previous_current_next = manual_previous_current_next;
