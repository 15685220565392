"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_all_auctions = void 0;
var api_1 = require("../../../api");
var get_all_auctions = function (client_id) {
    return (0, api_1.apiGet)({
        // endpoint: `v2/get_all_auctions?client_id=${client_id}`,
        endpoint: "v3/auction/auctions_for_client",
        query: { entity_id: client_id },
    });
};
exports.get_all_auctions = get_all_auctions;
