"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_user = void 0;
var api_1 = require("../../../api");
var update_user = function (request) {
    return (0, api_1.apiPatch)({
        endpoint: "v3/user/update",
        data: request,
    });
};
exports.update_user = update_user;
