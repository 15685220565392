"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloorVendorBidding = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var bid_increments_1 = require("../../../../../api/calls/client/bid_increments");
var react_redux_1 = require("react-redux");
var doshx_controls_web_2 = require("doshx_controls_web");
var place_floor_1 = require("../../../../../api/calls/bid/v3/place_floor");
var place_floor_custom_1 = require("../../../../../api/calls/bid/v3/place_floor_custom");
var main_1 = require("../../../../../reducers/slices/main");
var FloorVendorBidding = function (props) {
    var lot_entity_id = props.lot_entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _a = (0, react_1.useState)([]), increments = _a[0], setIncrements = _a[1];
    var _b = (0, react_1.useState)(null), selectedIncrement = _b[0], setSelectedIncrement = _b[1];
    var _c = (0, react_1.useState)(""), customBidAmount = _c[0], setCustomBidAmount = _c[1];
    var _d = (0, react_1.useState)("floor"), selectedType = _d[0], setSelectedType = _d[1];
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            (0, bid_increments_1.bid_increments)({
                client_entity_id: selectedClient.entity_id,
            }).then(function (response) {
                setIncrements(response.data.content.bid_increments);
            });
        }
    }, [selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id]);
    return (react_1.default.createElement("div", { className: "flex flex-col border-4 border-bluer rounded-xl" },
        react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-bluer text-white pb-1" }, "FLOOR / VENDOR BIDDING"),
        react_1.default.createElement("div", { className: "border-b-4 border-bluer flex flex-col items-center" },
            react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { selectedValue: selectedType, options: [
                    { value: "floor", label: "Floor" },
                    { value: "vendor", label: "Vendor" },
                ], onChange: function (value) {
                    setSelectedType(value);
                } }),
            react_1.default.createElement("div", { className: "pb-2 text-sm" }, "Choose which type of bid to place")),
        react_1.default.createElement("div", { className: "flex" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2 border-r-4 border-bluer p-2" },
                react_1.default.createElement("div", { className: "font-bold text-md" }, "Incremental Bidding:"),
                react_1.default.createElement("div", { className: "flex gap-2 flex-wrap justify-center" }, increments.map(function (increment, index) {
                    return (react_1.default.createElement("div", { key: "increment-".concat(index), className: "bg-bluer p-2 rounded-lg text-white cursor-pointer hover:bg-green-500\n                    select-none\n                ".concat(selectedIncrement === increment && "bg-red-500", "  \n              "), onClick: function () {
                            if (selectedIncrement !== increment) {
                                // indicate selected increment
                                setCustomBidAmount("");
                                setSelectedIncrement(increment);
                            }
                            else {
                                // place bid & reset selected increment
                                (0, place_floor_1.place_system_bid)({
                                    lot_entity_id: lot_entity_id,
                                    increment: increment,
                                    type: selectedType,
                                }).then(function (response) {
                                    if (response.data.success) {
                                        console.log("response", response);
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Error placing incremental ".concat(selectedType, " bid"), messages: [response.data.message] }));
                                    }
                                });
                                setSelectedIncrement(null);
                            }
                        } }, doshx_controls_web_2.Utilities.formatMoney({
                        value: increment.toString(),
                        decimalPlaces: 2,
                    })));
                })),
                selectedIncrement ? (react_1.default.createElement("div", { className: "flex justify-center items-center pb-2 text-sm" },
                    "CLICK increment AGAIN to place ",
                    selectedType,
                    " bid")) : (react_1.default.createElement("div", { className: "flex justify-center items-center pb-2 text-sm" },
                    "Select increment to place ",
                    selectedType,
                    " bid"))),
            react_1.default.createElement("div", { className: "p-2 flex flex-col gap-2 w-2/5" },
                react_1.default.createElement("div", { className: "font-bold text-md" }, "Custom Bidding:"),
                react_1.default.createElement("div", { className: "font-bold text-md" }, doshx_controls_web_2.Utilities.formatMoney({
                    value: customBidAmount == "" ? "0" : customBidAmount,
                    decimalPlaces: 2,
                })),
                react_1.default.createElement("input", { className: "h-10 border rounded-lg px-1", type: "number", value: customBidAmount, onChange: function (e) {
                        var val = e.target.value;
                        setSelectedIncrement(null);
                        setCustomBidAmount(val !== null && val !== void 0 ? val : "");
                    }, onKeyDown: function (e) {
                        if (e.key === "Enter") {
                            (0, place_floor_custom_1.place_system_custom_bid)({
                                lot_entity_id: lot_entity_id,
                                amount: parseFloat(customBidAmount),
                                type: selectedType,
                            }).then(function (response) {
                                if (response.data.success) {
                                    console.log("response", response);
                                    setCustomBidAmount("");
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Error placing custom ".concat(selectedType, " bid"), messages: [response.data.message] }));
                                }
                            });
                        }
                    } }),
                customBidAmount ? (
                // <div className="flex gap-2 justify-center border-t border-bluer pt-2">
                //   <div
                //     className="text-lg bg-green-500 rounded-lg text-white cursor-pointer flex justify-center items-center max-w-56 px-2 py-1"
                //     onClick={() => {
                //       place_system_custom_bid({
                //         lot_entity_id: lot_entity_id,
                //         amount: parseFloat(customBidAmount),
                //         type: selectedType,
                //       }).then((response) => {
                //         if (response.data.success) {
                //           console.log("response", response);
                //           setCustomBidAmount("");
                //         } else {
                //           dispatch(setMessageModal({ title: `Error placing custom ${selectedType} bid`, messages: [response.data.message] }));
                //         }
                //       });
                //     }}
                //   >
                //     CUSTOM BID
                //   </div>
                //   <div
                //     className="text-lg bg-red-500 rounded-lg w-10 flex justify-center items-center text-white cursor-pointer"
                //     onClick={() => {
                //       setCustomBidAmount("");
                //     }}
                //   >
                //     <FontAwesomeIcon icon={faTimes} />
                //   </div>
                // </div>
                react_1.default.createElement("div", { className: "text-sm text-center" },
                    "Press ENTER to place custom ",
                    selectedType,
                    " bid")) : (react_1.default.createElement("div", { className: "text-sm text-center" },
                    "Enter custom bid amount to place ",
                    selectedType,
                    " bid"))))));
};
exports.FloorVendorBidding = FloorVendorBidding;
