"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
// import moment from "moment";
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var details_1 = require("./details");
var images_1 = require("./images");
var documents_1 = require("./documents");
var get_lot_1 = require("../../../api/calls/lot/v3/get_lot");
var delete_lot_1 = require("../../../api/calls/lot/v3/delete_lot");
var colors_1 = require("../../../constants/colors");
var LotScreen = function (props) {
    var onSuccess = props.onSuccess, scrollRef = props.scrollRef;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _a = params.lot_entity_id, lot_entity_id = _a === void 0 ? "" : _a;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    // const { lots, selectedLot, isEditing } = useSelector<any, ILotSliceState>((state) => state.lotState);
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var _b = (0, react_1.useState)(false), isSaving = _b[0], setIsSaving = _b[1];
    var _c = (0, react_1.useState)(), lot = _c[0], setLot = _c[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!(mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id)) {
            navigate("/console/lots");
        }
        else {
            if (lot_entity_id) {
                (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
                (0, get_lot_1.get_lot)({
                    entity_id: lot_entity_id,
                }).then(function (response) {
                    if (response.data.success) {
                        setLot(response.data.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting the lot!", messages: [response.data.message] }));
                    }
                });
            }
        }
    }, []);
    (0, react_1.useEffect)(function () {
        // Go back to the lots page if the user changed the main selected auction and it doesn't match the current lot's auction.
        if ((mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) &&
            (lot //
                ? lot.auction_entity_id !== (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id)
                : false)) {
            navigate("/console/lots");
        }
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 p-4 w-full" },
            react_1.default.createElement("div", { className: "text-2xl" }, mainAuction === null || mainAuction === void 0 ? void 0 :
                mainAuction.title,
                " - Lot Details - #", lot === null || lot === void 0 ? void 0 :
                lot.lot_number,
                " - ", lot === null || lot === void 0 ? void 0 :
                lot.title),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-start" }, (lot === null || lot === void 0 ? void 0 : lot.entity_id) && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete This Lot", backgroundColor: "bg-red-600", size: "small", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Are you sure?",
                        messages: ["Are you sure that you want to delete this lot?"],
                        buttons: "delete",
                        onConfirm: function () {
                            (0, delete_lot_1.delete_lot)({
                                entity_id: lot.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    dispatch((0, main_1.setMessageModal)(null));
                                    navigate("/console/lots");
                                    onSuccess();
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                                }
                            });
                        },
                    }));
                } }))),
            react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: colors_1.tabColors, tabs: [
                    {
                        title: "Details",
                        content: (react_1.default.createElement(details_1.LotDetailsTab, { lot_id: lot === null || lot === void 0 ? void 0 : lot.entity_id, onSave: function (lot) {
                                // dispatch(
                                //   setSelectedLot({
                                //     entity_id: lot_id,
                                //   }),
                                // );
                                // navigate(`/console/lots/lot/${lot_id}`);
                                setLot(lot);
                                onSuccess();
                            } })),
                    },
                    {
                        title: "Images",
                        content: react_1.default.createElement(images_1.LotImagesTab, { lot_entity_id: lot === null || lot === void 0 ? void 0 : lot.entity_id }),
                    },
                    { title: "Documents", content: react_1.default.createElement(documents_1.LotDocumentsTab, { lot_entity_id: lot === null || lot === void 0 ? void 0 : lot.entity_id }) },
                ] }))));
};
exports.LotScreen = LotScreen;
