"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_bidder = void 0;
var api_1 = require("../../../api");
var get_bidder = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/bidder/get",
        query: req,
    });
};
exports.get_bidder = get_bidder;
