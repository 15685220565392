"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retrieve_file = void 0;
var api_1 = require("../../api");
var retrieve_file = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v3/file/retrieve",
        data: request,
    });
};
exports.retrieve_file = retrieve_file;
