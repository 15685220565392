"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalControl = void 0;
var react_1 = __importStar(require("react"));
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var ModalControl = function (props) {
    var _a = props !== null && props !== void 0 ? props : {}, title = _a.title, close = _a.close, isOpen = _a.isOpen;
    var _b = close !== null && close !== void 0 ? close : {}, showClose = _b.showClose, onClose = _b.onClose;
    var _c = (0, react_1.useState)(false), visible = _c[0], setVisible = _c[1];
    (0, react_1.useEffect)(function () {
        setVisible(isOpen);
    }, [isOpen]);
    return visible ? (react_1.default.createElement("div", { className: "absolute top-0 bottom-0 left-0 right-0 z-40 flex justify-center items-center p-4 overflow-y-auto bg-modal" },
        react_1.default.createElement("div", { className: "flex flex-col bg-darkish rounded-lg shadow-lg \n          min-w-modal-width max-w-modal-width" },
            react_1.default.createElement("div", { className: "flex flex-row items-center justify-between" }, title && (react_1.default.createElement("div", { className: "w-full" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement("div", { className: "text-xl text-white p-4" }, title),
                    showClose && (react_1.default.createElement("div", { className: "w-8 h-8 mt-4 mr-4 cursor-pointer bg-bluer rounded-lg flex flex-none items-center justify-center", onClick: function () {
                            setVisible(false);
                            if (onClose) {
                                onClose(false);
                            }
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "text-xl text-white", icon: pro_light_svg_icons_1.faTimes })))),
                react_1.default.createElement("div", { className: "border-b ml-4 mr-4" })))),
            react_1.default.createElement("div", { className: "px-4 pb-4" }, props.children)))) : (react_1.default.createElement("div", { className: "hidden" }));
};
exports.ModalControl = ModalControl;
