"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_all_bids = void 0;
var api_1 = require("../../api");
var get_all_bids = function (lot_id) {
    return (0, api_1.apiGet)({
        endpoint: "v2/admin/get_all_bids?lot_id=".concat(lot_id),
    });
};
exports.get_all_bids = get_all_bids;
