"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersScreen = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_all_client_users_1 = require("../../../api/calls/users/get_all_client_users");
var main_1 = require("../../../reducers/slices/main");
var details_1 = require("./details");
var delete_user_1 = require("../../../api/calls/users/v3/delete_user");
var export_user_emails_csv_1 = require("../../../api/calls/client/export_user_emails_csv");
var UsersScreen = function (props) {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _b = (0, react_1.useState)([]), users = _b[0], setUsers = _b[1];
    var _c = (0, react_1.useState)(0), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    (0, react_1.useEffect)(function () {
        if (selectedClient && selectedClient.entity_id) {
            refreshUsers();
        }
    }, [selectedClient]);
    var refreshUsers = function () {
        (0, get_all_client_users_1.get_all_client_users)(selectedClient.entity_id).then(function (response) {
            if (response.data.success) {
                console.log("response.data.content", response.data.content);
                setUsers(response.data.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Users", messages: [response.data.message] }));
            }
        });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4" },
        react_1.default.createElement(react_router_dom_1.Routes, null,
            react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        react_1.default.createElement("div", { className: "text-2xl font-bold flex-1" },
                            "Users for ", (_a = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.name) !== null && _a !== void 0 ? _a : "[select a client]")),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Manually Create User", backgroundColor: "bg-green-600", size: "small", onClick: function () {
                                if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
                                    // dispatch(setContentModal({ title: "Create User", content: <UserDetailsPage refreshUsers={refreshUsers} /> }));
                                    navigate("".concat(pathname, "/user"));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Create User", messages: ["First select a client to create a user for."] }));
                                }
                            } }),
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Export Emails CSV", backgroundColor: "bg-cyan-500", size: "small", onClick: function () {
                                if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
                                    (0, export_user_emails_csv_1.export_user_emails_csv)({
                                        client_entity_id: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id,
                                    }).then(function (response) {
                                        if (response.data.success) {
                                            var csv = response.data.content;
                                            var element = document.createElement("a");
                                            var file = new Blob([csv], { type: "text/csv" });
                                            element.style.display = "none";
                                            element.href = URL.createObjectURL(file);
                                            element.download = "client_user_emails_export.csv";
                                            element.click();
                                            element.remove();
                                        }
                                    });
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Export Emails CSV", messages: ["First select a client to export user emails."] }));
                                }
                            } })),
                    react_1.default.createElement(doshx_controls_web_1.TableControl
                    // title="Users"
                    , { 
                        // title="Users"
                        onSearch: function (searchVal) {
                            //
                        }, columns: [
                            { label: "Name", displayKey: "name" },
                            { label: "Surname", displayKey: "surname" },
                            { label: "Email", displayKey: "email" },
                            {
                                label: "Phone Number",
                                displayKey: "",
                                formatter: function (obj) {
                                    var _a, _b;
                                    console.log("obj", obj);
                                    return "".concat((_a = obj.cell_phone) === null || _a === void 0 ? void 0 : _a.calling_code).concat((_b = obj.cell_phone) === null || _b === void 0 ? void 0 : _b.number);
                                },
                            },
                        ], data: users, 
                        // onSearch={(search) => {
                        //   console.log("search", search);
                        // }}
                        // pagination={{
                        //   totalRecords: users.length,
                        //   paginationPosition: "left",
                        //   rowsPerPage: rowsPerPage,
                        //   currentPage: currentPage,
                        //   onPageChange: (page) => {
                        //     setCurrentPage(page);
                        //   },
                        //   onRowsPerPageChange: (rows) => {
                        //     setRowsPerPage(rows);
                        //   },
                        // }}
                        onRowClicked: function (row) {
                            navigate("".concat(pathname, "/user/").concat(row.entity_id));
                        }, rowActions: [
                            {
                                label: "Delete",
                                onClick: function (row) {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Delete User Account",
                                        messages: ["Are you sure you want to delete this user account?"],
                                        buttons: "yesNo",
                                        onConfirm: function () {
                                            (0, delete_user_1.delete_user)({
                                                user_entity_id: row.entity_id,
                                            }).then(function (e) {
                                                refreshUsers();
                                                dispatch((0, main_1.setMessageModal)(null));
                                            });
                                        },
                                    }));
                                },
                            },
                        ], colors: {
                            titleBackgroundColor: "bg-blue-600",
                            headerBackgroundColor: "bg-bluish",
                            headerTextColor: "text-white",
                            ordering: {
                                borderColor: "border-bluish",
                            },
                            hoverRowColor: "hover:bg-gray-200",
                        } })) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "/user/:user_entity_id?", element: react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        react_1.default.createElement("div", { className: "text-2xl font-bold flex-1" }, "User Details")),
                    react_1.default.createElement(details_1.UserDetailsPage, { onSuccess: function () {
                            refreshUsers();
                        } })) }))));
};
exports.UsersScreen = UsersScreen;
