"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_lot = void 0;
var api_1 = require("../../../api");
var delete_lot = function (req) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/lot/delete",
        // data: formData,
        data: req,
    });
};
exports.delete_lot = delete_lot;
