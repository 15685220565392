"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedBidder = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var slice = (0, toolkit_1.createSlice)({
    name: "bidder",
    initialState: {
        selectedBidder: {},
    },
    reducers: {
        setSelectedBidder: function (state, action) {
            state.selectedBidder = action.payload;
        },
    },
});
exports.setSelectedBidder = slice.actions.setSelectedBidder;
exports.default = slice.reducer;
