"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_registered_users_for_auction = void 0;
var api_1 = require("../api");
var get_registered_users_for_auction = function (auction_id) {
    return (0, api_1.apiGet)({
        endpoint: "v2/admin/get_registered_users_for_auction?auction_id=".concat(auction_id !== null && auction_id !== void 0 ? auction_id : ""),
    });
};
exports.get_registered_users_for_auction = get_registered_users_for_auction;
