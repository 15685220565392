"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
// import moment from "moment";
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
// import auction, { IAuctionSliceState, setSelectedAuction } from "../../../reducers/slices/auction";
var details_1 = require("./details");
var images_1 = require("./images");
var documents_1 = require("./documents");
var get_auction_1 = require("../../../api/calls/auction/v3/get_auction");
var delete_auction_1 = require("../../../api/calls/auction/v3/delete_auction");
var console_1 = require("../../../reducers/slices/console");
var AuctionScreen = function (props) {
    var onSuccess = props.onSuccess;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _a = params.auction_entity_id, auction_entity_id = _a === void 0 ? "" : _a;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    // const { auctions, selectedAuction, isEditing } = useSelector<any, IAuctionSliceState>((state) => state.auctionState);
    var _b = (0, react_1.useState)({
        // client,
        entity_id: auction_entity_id,
    }), auction = _b[0], setAuction = _b[1];
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            // Go back to the auctions page if the user changed the main selected client and it doesn't match the current auction's client.
            if ((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) &&
                auction.client_entity_id && //
                auction.client_entity_id !== (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id)) {
                navigate("/console/auctions");
            }
        }
        else {
            navigate("/console/auctions");
        }
    }, [selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id]);
    (0, react_1.useEffect)(function () {
        if (auction_entity_id) {
            (0, get_auction_1.get_auction)({
                entity_id: auction_entity_id,
            }).then(function (response) {
                console.log("FREDEDD", response);
                if (response.data.success) {
                    // dispatch(setSelectedAuction(response.data.content));
                    setAuction(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                }
            });
        }
    }, [auction_entity_id]);
    // useEffect(() => {
    //   if (!selectedClient?.entity_id) {
    //     navigate("/console/auctions");
    //   } else {
    //     console.log("params", params);
    //     console.log("selectedAuction", selectedAuction);
    //     if (selectedAuction.entity_id) {
    //       get_auction({
    //         entity_id: selectedAuction.entity_id,
    //       }).then((response) => {
    //         console.log("FREDEDD", response);
    //         if (response.data.success) {
    //           dispatch(setSelectedAuction(response.data.content));
    //         } else {
    //           dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.data.message] }));
    //         }
    //       });
    //     }
    //   }
    // }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 p-4 w-full" },
            react_1.default.createElement("div", { className: "text-2xl font-bold" }, selectedClient === null || selectedClient === void 0 ? void 0 :
                selectedClient.name,
                " - Auction Details - ", auction === null || auction === void 0 ? void 0 :
                auction.title),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 justify-start" }, (auction === null || auction === void 0 ? void 0 : auction.entity_id) && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete This Auction", backgroundColor: "bg-red-600", size: "small", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Are you sure?",
                        messages: ["Are you sure that you want to delete this auction?"],
                        buttons: "yesNo",
                        onConfirm: function () {
                            (0, delete_auction_1.delete_auction)({
                                entity_id: auction.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    dispatch((0, main_1.setMessageModal)(null));
                                    navigate("/console/auctions");
                                    onSuccess();
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                                }
                            });
                        },
                    }));
                } }))),
            react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: {
                    borderColor: "border-gray-300",
                    tabs: {
                        backgroundColor: "bg-bluish",
                        textColor: "text-white",
                        indicatorColor: "bg-orange-400",
                    },
                }, tabs: [
                    {
                        title: "Details",
                        content: (react_1.default.createElement(details_1.AuctionDetailsTab, { auction_id: auction.entity_id, onSave: function (res) {
                                setAuction(__assign(__assign({}, auction), { title: res.title, entity_id: res.entity_id }));
                                // also update the main auction in the console slice so that currently selected auction is updated.
                                if (res.entity_id === (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id)) {
                                    console.log("updating mainAuction", res);
                                    dispatch((0, console_1.setMainAuction)(res));
                                }
                                // navigate(`/console/auctions/auction/${auction_entity_id}`);
                                onSuccess();
                            } })),
                    },
                    {
                        title: "Images",
                        content: react_1.default.createElement(images_1.AuctionImagesTab, { auction_entity_id: auction === null || auction === void 0 ? void 0 : auction.entity_id }),
                    },
                    { title: "Documents", content: react_1.default.createElement(documents_1.AuctionDocumentsTab, { auction_entity_id: auction === null || auction === void 0 ? void 0 : auction.entity_id }) },
                ] }))));
};
exports.AuctionScreen = AuctionScreen;
