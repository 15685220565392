"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_social_media = void 0;
var api_1 = require("../../api");
var get_social_media = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/client/get_social_media",
        query: req,
    });
};
exports.get_social_media = get_social_media;
