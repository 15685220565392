"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_auction = void 0;
var api_1 = require("../../../api");
var delete_auction = function (req) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/auction/delete",
        // data: formData,
        data: req,
    });
};
exports.delete_auction = delete_auction;
