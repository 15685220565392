"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_configuration = void 0;
var store_1 = require("../../store");
var api_1 = require("../api");
var get_configuration = function () {
    var _a = store_1.redux_store.getState(), _b = _a.userState.user, role = _b.admin.role, client_token = _b.client_token, selectedClient = _a.clientState.selectedClient;
    return (0, api_1.apiGet)({
        endpoint: "v2/config/get_configuration",
        customHeaders: {
            client_token: role == "super_admin" ? selectedClient.token : client_token,
        },
    });
};
exports.get_configuration = get_configuration;
