"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auction_report_csv = void 0;
var api_1 = require("../../../api");
var auction_report_csv = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/reporting/auction_report_csv",
        query: request,
    });
};
exports.auction_report_csv = auction_report_csv;
