"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPreservedScroll = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    // clients: [],
    preserved_scroll: {}, // { [pathname]: string }
};
var slice = (0, toolkit_1.createSlice)({
    name: "preserved_scroll",
    initialState: initialState,
    reducers: {
        setPreservedScroll: function (state, action) {
            state.preserved_scroll = action.payload;
        },
    },
});
exports.setPreservedScroll = slice.actions.setPreservedScroll;
exports.default = slice.reducer;
