"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedClient = exports.setClients = exports.get_all_clients_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var get_all_clients_1 = require("../../api/calls/client/get_all_clients");
var main_1 = require("./main");
var get_all_clients_thunk = (0, toolkit_1.createAsyncThunk)("user/get_all_clients_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, user, response;
        return __generator(this, function (_b) {
            state = getState();
            console.log("get_all_clients_thunk STATE", state);
            user = state.userState.user;
            (0, get_all_clients_1.get_all_clients)().then(function (response) {
                if (response.data.success) {
                    dispatch((0, exports.setClients)(response.data.content));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Failed to get all clients!", messages: [response.data.message] }));
                }
            });
            response = { success: true, message: "Success", content: { user: {}, token: "1234" } };
            return [2 /*return*/, response];
        });
    });
});
exports.get_all_clients_thunk = get_all_clients_thunk;
var slice = (0, toolkit_1.createSlice)({
    name: "client",
    initialState: {
        clients: [],
        selectedClient: null,
        isLoading: false,
    },
    reducers: {
        setClients: function (state, action) {
            state.clients = action.payload;
        },
        setSelectedClient: function (state, action) {
            state.selectedClient = action.payload;
        },
        // setClient: (state, action) => {
        //   state.client = action.payload;
        // },
    },
    extraReducers: function (builder) {
        builder
            .addCase(get_all_clients_thunk.fulfilled, function (state, action) {
            console.log("get_all_clients_thunk.fulfilled", action);
            state.isLoading = false;
        })
            .addCase(get_all_clients_thunk.rejected, function (state, action) {
            console.log("get_all_clients_thunk.rejected", action);
            state.isLoading = false;
        })
            .addCase(get_all_clients_thunk.pending, function (state, action) {
            console.log("get_all_clients_thunk.pending", action);
            state.isLoading = true;
        });
    },
});
exports.setClients = (_a = slice.actions, _a.setClients), exports.setSelectedClient = _a.setSelectedClient;
exports.default = slice.reducer;
