"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploaderControl = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants/constants");
var functions_1 = require("../../constants/functions");
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var upload_1 = require("../../api/calls/file/upload");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var delete_1 = require("../../api/calls/file/delete");
var retrieve_1 = require("../../api/calls/file/retrieve");
var get_auction_1 = require("../../api/calls/auction/v3/get_auction");
var get_lot_1 = require("../../api/calls/lot/v3/get_lot");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var FileUploaderControl = function (props) {
    var auction_entity_id = props.auction_entity_id, lot_entity_id = props.lot_entity_id, user_entity_id = props.user_entity_id, bidder_entity_id = props.bidder_entity_id, type = props.type, onOrderChange = props.onOrderChange;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), files = _a[0], setFiles = _a[1];
    var _b = (0, react_1.useState)({}), uploadProgress = _b[0], setUploadProgress = _b[1];
    var _c = (0, react_1.useState)(null), auction = _c[0], setAuction = _c[1];
    var _d = (0, react_1.useState)(null), lot = _d[0], setLot = _d[1];
    // const [bidderDocuments, setBidderDocuments] = useState<any[]>([]);
    var _e = (0, react_1.useState)({}), orderValues = _e[0], setOrderValues = _e[1];
    var fileInputRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (auction_entity_id) {
            (0, get_auction_1.get_auction)({
                entity_id: auction_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    // setAuction(response.data.content);
                    console.log("response.data.content", response.data.content);
                    switch (type) {
                        case "Image":
                            loadFiles(response.data.content.images);
                            break;
                        case "Document":
                            loadFiles(response.data.content.documents);
                            break;
                    }
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                }
            });
        }
        if (lot_entity_id) {
            (0, get_lot_1.get_lot)({
                entity_id: lot_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    setLot(response.data.content);
                    console.log("response.data.contena sd ad t", response.data.content);
                    switch (type) {
                        case "Image":
                            loadFiles(response.data.content.images);
                            break;
                        case "Document":
                            loadFiles(response.data.content.documents);
                            break;
                    }
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                }
            });
        }
        if (bidder_entity_id) {
            (0, retrieve_1.retrieve_file)({
                bidder_entity_id: bidder_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    // setBidderDocuments(response.data.content);
                    console.log("response.data.content", response.data.content);
                    loadFiles(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting bidder documents!", messages: [response.data.message] }));
                }
            });
        }
        if (user_entity_id) {
            // TODO
        }
    }, []);
    (0, react_1.useEffect)(function () {
        var order = gatherOrder();
        onOrderChange && onOrderChange(order);
    }, [files]);
    var loadFiles = function (fileArr) { return __awaiter(void 0, void 0, void 0, function () {
        var fls, _i, fileArr_1, f, file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("fileArr", fileArr);
                    if (!fileArr) return [3 /*break*/, 5];
                    fls = [];
                    _i = 0, fileArr_1 = fileArr;
                    _a.label = 1;
                case 1:
                    if (!(_i < fileArr_1.length)) return [3 /*break*/, 4];
                    f = fileArr_1[_i];
                    if (!f.uploaded_file_url) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, functions_1.urlToFile)(f.uploaded_file_url)];
                case 2:
                    file = _a.sent();
                    fls.push(__assign(__assign({}, f), { file: file }));
                    _a.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    // setIsImagesLoading(false);
                    setFiles(fls);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleFiles = function (newFiles) { return __awaiter(void 0, void 0, void 0, function () {
        var newFilesArray, newFilesArrayWithCustomNames, messages, _loop_1, _i, newFilesArray_1, f;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    newFilesArray = Array.from(newFiles);
                    newFilesArrayWithCustomNames = [];
                    messages = [];
                    _loop_1 = function (f) {
                        var fObj;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    fObj = {
                                        // uuid: uploadUid,
                                        custom_name: f.name,
                                        auction_id: auction_entity_id,
                                        lot_id: lot_entity_id,
                                        user_id: user_entity_id,
                                        bidder_id: bidder_entity_id,
                                        type: type,
                                        file: f,
                                    };
                                    // check if the file extension is supported
                                    if (type === "Image") {
                                        if (!constants_1.SUPPORTED_IMAGE_EXTENSIONS.includes(".".concat((_a = f.name.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase()))) {
                                            messages.push("The image file ".concat(f.name, " is not supported."));
                                            return [2 /*return*/, "continue"];
                                        }
                                    }
                                    if (type === "Document") {
                                        if (!constants_1.SUPPORTED_DOCUMENT_EXTENSIONS.includes(".".concat((_b = f.name.split(".").pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase()))) {
                                            messages.push("The document file ".concat(f.name, " is not supported."));
                                            return [2 /*return*/, "continue"];
                                        }
                                    }
                                    setUploadProgress(function (prev) {
                                        var _a;
                                        return __assign(__assign({}, prev), (_a = {}, _a[fObj.file.name] = 0, _a));
                                    });
                                    newFilesArrayWithCustomNames.push(fObj);
                                    //Append to files
                                    setFiles(function (prevFiles) { return __spreadArray(__spreadArray([], prevFiles, true), [fObj], false); });
                                    return [4 /*yield*/, (0, upload_1.upload_file)({
                                            auction_id: auction_entity_id,
                                            lot_id: lot_entity_id,
                                            bidder_id: bidder_entity_id,
                                            user_id: user_entity_id,
                                            type: type,
                                            file: f,
                                            uploadProgress: function (percentCompleted) { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    console.log("percentCompleted", percentCompleted);
                                                    // fObj.progress = percentCompleted;
                                                    // Update the progress
                                                    // await setFiles((prevFiles) => [...prevFiles]);
                                                    setUploadProgress(function (prev) {
                                                        var _a;
                                                        return __assign(__assign({}, prev), (_a = {}, _a[fObj.file.name] = percentCompleted, _a));
                                                    });
                                                    return [2 /*return*/];
                                                });
                                            }); },
                                        }).then(function (response) {
                                            console.log("response", response);
                                            if (response.data.success) {
                                                fObj.entity_id = response.data.content.entity_id;
                                                fObj.uploaded_file_url = response.data.content.uploaded_file_url;
                                                setFiles(function (prevFiles) { return __spreadArray([], prevFiles, true); });
                                                // let order = gatherOrder();
                                                // onOrderChange && onOrderChange(order);
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                                            }
                                        })];
                                case 1:
                                    _d.sent();
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, newFilesArray_1 = newFilesArray;
                    _c.label = 1;
                case 1:
                    if (!(_i < newFilesArray_1.length)) return [3 /*break*/, 4];
                    f = newFilesArray_1[_i];
                    return [5 /*yield**/, _loop_1(f)];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    if (messages.length > 0) {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Some files were not uploaded!",
                            messages: messages,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Images uploaded successfully!",
                            messages: [],
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteFile = function (file_id) {
        setFiles(function (prevFiles) { return prevFiles.filter(function (file) { return file.entity_id !== file_id; }); });
        fileInputRef.current.value = "";
        (0, delete_1.delete_file)({ file_entity_id: file_id }).then(function (response) {
            console.log("response", response);
            // let order = gatherOrder();
            // onOrderChange && onOrderChange(order);
        });
    };
    var DragHere = function () {
        return (react_1.default.createElement("div", { className: "border-4 border-dashed \n        border-black text-black \n        text-2xl w-full rounded-xl p-4 \n        cursor-pointer text-center hover:underline", onClick: function () {
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
            }, onDragOver: function (e) {
                e.preventDefault();
            }, onDrop: function (e) {
                e.preventDefault();
                handleFiles(e.dataTransfer.files);
            } }, type === "Image" ? "Drag images here, or click to upload" : "Drag documents here, or click to upload"));
    };
    var bytesToMB = function (bytes) {
        return (bytes / (1024 * 1024)).toFixed(2);
    };
    function gatherOrder() {
        var order = [];
        files.map(function (f, index) {
            order.push({
                entity_id: f.entity_id,
                order: index + 1,
            });
        });
        return order;
    }
    return (react_1.default.createElement("div", { className: "flex flex-col w-full min-h-[100px]" },
        react_1.default.createElement("div", { className: "w-full" },
            react_1.default.createElement("input", { ref: fileInputRef, className: "hidden", type: "file" //
                , accept: type === "Image" ? constants_1.SUPPORTED_IMAGE_EXTENSIONS.join(",") : constants_1.SUPPORTED_DOCUMENT_EXTENSIONS.join(","), multiple: true, onChange: function (e) {
                    if (e.target.files) {
                        handleFiles(e.target.files);
                    }
                } }),
            files.length == 0 ? (react_1.default.createElement(DragHere, null)) : (react_1.default.createElement("div", { className: "relative mt-2 flex flex-col gap-8" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(DragHere, null)),
                react_1.default.createElement("div", { className: "z-10 flex flex-row gap-4 flex-wrap" }, files.map(function (f, index) {
                    var _a, _b, _c;
                    switch (type) {
                        case "Image":
                            var entID_1 = (_b = (_a = f.entity_id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
                            var move_1 = function () {
                                var newFiles = __spreadArray([], files, true);
                                var removed = newFiles.splice(index, 1);
                                newFiles.splice(orderValues[entID_1] - 1, 0, removed[0]);
                                setFiles(newFiles);
                                var order = gatherOrder();
                                onOrderChange && onOrderChange(order);
                                // clear the input
                                setOrderValues({});
                            };
                            return (react_1.default.createElement("div", { key: index, className: "relative w-44 h-44 flex flex-col" },
                                react_1.default.createElement("img", { className: "w-full h-full object-cover ".concat(f.uploaded_file_url ? "rounded-lg" : "rounded-t-lg"), src: URL.createObjectURL(f.file) }),
                                !f.uploaded_file_url && (react_1.default.createElement("div", { className: "h-2 w-full rounded-b-lg overflow-clip" },
                                    react_1.default.createElement("div", { className: "bg-red-400 h-full", style: {
                                            width: "".concat(uploadProgress[f.file.name], "%"),
                                        } }))),
                                react_1.default.createElement("div", { className: "w-28 h-8 rounded-full bg-white text-black text-sm absolute\n                          -top-2 flex justify-center items-center cursor-pointer self-center\n                          shadow-[0_0px_10px_0px_rgba(0,0,0,0.3)]" },
                                    index + 1,
                                    " >",
                                    react_1.default.createElement("span", null,
                                        react_1.default.createElement("input", { 
                                            // ref={}
                                            className: "w-12 border ml-2 rounded-full text-center", type: "number", value: (_c = orderValues[entID_1]) !== null && _c !== void 0 ? _c : "", onChange: function (e) {
                                                var indexStr = e.currentTarget.value;
                                                var indexInt = parseInt(indexStr);
                                                console.log("indexInt", indexInt);
                                                setOrderValues(function (prev) {
                                                    var _a;
                                                    return _a = {},
                                                        // ...prev,
                                                        _a[entID_1] = indexInt,
                                                        _a;
                                                });
                                            }, onKeyUp: function (e) {
                                                // if enter is pressed
                                                if (e.key === "Enter") {
                                                    move_1();
                                                }
                                            } })),
                                    orderValues[entID_1] > 0 && (react_1.default.createElement("span", { className: "absolute -top-2 right-0 w-6 h-6 bg-green-500 rounded-full text-sm text-white flex justify-center items-center", onClick: function () {
                                            move_1();
                                        } },
                                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCheck })))),
                                react_1.default.createElement("div", { className: "w-8 h-8 rounded-full bg-red-500 text-white text-sm absolute \n                          -top-2 -right-3 flex justify-center items-center cursor-pointer\n                          shadow-[0_0px_10px_0px_rgba(0,0,0,0.3)]", onClick: function () {
                                        deleteFile(f.entity_id);
                                    } },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes }))));
                        case "Document":
                            return (react_1.default.createElement("div", { key: index, className: "relative  " },
                                react_1.default.createElement("div", { className: "w-8 h-8 rounded-full bg-red-500 text-white text-sm absolute \n                          -top-3 -right-3 flex justify-center items-center cursor-pointer", onClick: function () {
                                        deleteFile(f.entity_id);
                                    } },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes })),
                                react_1.default.createElement("div", { key: index, className: "text-md text-white bg-bluish \n                          ".concat(f.uploaded_file_url ? "rounded-lg" : "rounded-t-lg", " \n                          p-3") },
                                    f.file.name,
                                    " - ",
                                    bytesToMB(f.file.size),
                                    "MB"),
                                bidder_entity_id && react_1.default.createElement("div", { className: "" }, f.custom_name),
                                !f.uploaded_file_url && (react_1.default.createElement("div", { className: "h-2 w-full rounded-b-lg overflow-clip" },
                                    react_1.default.createElement("div", { className: "bg-red-400 h-full", style: {
                                            width: "".concat(uploadProgress[f.file.name], "%"),
                                        } })))));
                    }
                })))))));
};
exports.FileUploaderControl = FileUploaderControl;
