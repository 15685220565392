"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidsForLot = void 0;
var react_1 = __importStar(require("react"));
var bids_for_lot_1 = require("../../../../../api/calls/bid/v3/bids_for_lot");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var socket_1 = require("../../../../../socket");
var functions_1 = require("../../../../../constants/functions");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var reject_bid_1 = require("../../../../../api/calls/bid/v3/reject_bid");
var back_up_bid_1 = require("../../../../../api/calls/bid/v3/back_up_bid");
var BidsForLot = function (props) {
    var _a;
    var _b = props.showRejectBackUp, showRejectBackUp = _b === void 0 ? true : _b, 
    // showBidNotification = false,
    lot_entity_id = props.lot_entity_id, newBid = props.newBid;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)({
        count: 0,
        bids: [],
    }), bids = _c[0], setBids = _c[1];
    var _d = (0, react_1.useState)(null), rejectedBid = _d[0], setRejectedBid = _d[1];
    var _e = (0, react_1.useState)(null), backedUpBids = _e[0], setBackedUpBids = _e[1];
    var _f = (0, react_1.useState)([]), incomingBids = _f[0], setIncomingBids = _f[1];
    (0, react_1.useEffect)(function () {
        if (rejectedBid || backedUpBids) {
            getBidsForLot();
        }
    }, [rejectedBid, backedUpBids]);
    (0, react_1.useEffect)(function () {
        if (lot_entity_id) {
            socket_1.socket.off("newBid");
            socket_1.socket.on("newBid", function (data) {
                console.log("newBid bids for lot", data);
                var bid = data.bid, count = data.count;
                var lot = bid.lot;
                if (lot.entity_id == lot_entity_id) {
                    var newLotsWithBids = __spreadArray([bid], bids.bids, true);
                    newLotsWithBids = newLotsWithBids.slice(0, 10); // get the first 10 bids
                    // if (bid.user.entity_id != FLOOR_ENTITY_ID) {
                    // add for 5 seconds, then remove the id from the array again to simulate a new incoming bid highlight.
                    setIncomingBids(function (incomingBids) { return __spreadArray(__spreadArray([], incomingBids, true), [bid.entity_id], false); });
                    setTimeout(function () {
                        setIncomingBids(function (incomingBids) { return incomingBids.filter(function (id) { return id !== bid.entity_id; }); });
                    }, 5000);
                    // }
                    setBids({
                        count: count,
                        bids: newLotsWithBids,
                    });
                    if (newBid) {
                        newBid(data);
                    }
                }
            });
            socket_1.socket.off("rejectBid");
            socket_1.socket.on("rejectBid", function (data) {
                console.log("rejectBid", data);
                var lot_entity_id = data.lot_entity_id, bid_entity_id = data.bid_entity_id;
                setRejectedBid(data);
            });
            socket_1.socket.off("backUpBids");
            socket_1.socket.on("backUpBids", function (data) {
                console.log("backUpBids", data);
                var lot_entity_id = data.lot_entity_id, bid_entity_ids = data.bid_entity_ids;
                setBackedUpBids(data);
            });
        }
    }, [lot_entity_id, bids]);
    (0, react_1.useEffect)(function () {
        if (lot_entity_id) {
            socket_1.socket.emit("joinLotPipe", lot_entity_id);
            (0, bids_for_lot_1.bids_for_lot)({ entity_id: lot_entity_id }).then(function (response) {
                if (response.data.success) {
                    setBids(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Error", messages: [response.data.message] }));
                }
            });
        }
    }, [lot_entity_id]);
    function getBidsForLot() {
        (0, bids_for_lot_1.bids_for_lot)({ entity_id: lot_entity_id }).then(function (response) {
            if (response.data.success) {
                setBids(response.data.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Error", messages: [response.data.message] }));
            }
        });
    }
    return (react_1.default.createElement("div", { className: "flex flex-col border-bluer border-4 rounded-xl overflow-clip" },
        react_1.default.createElement("div", { className: "flex justify-between items center bg-bluer" },
            react_1.default.createElement("div", { className: "text-xl font-bold px-4 py-2 text-white" }, "CURRENT BIDS"),
            react_1.default.createElement("div", { className: "flex flex-col pr-4 items-center justify-center text-white" },
                react_1.default.createElement("div", { className: "text-sm font-bold" }, (_a = bids === null || bids === void 0 ? void 0 : bids.count) !== null && _a !== void 0 ? _a : 0),
                react_1.default.createElement("div", { className: "text-xs" }, "TOTAL BIDS"))),
        react_1.default.createElement("div", { className: "flex flex-col" },
            bids.bids && bids.bids.length > 0 ? (bids.bids.map(function (bid, index) {
                return (react_1.default.createElement("div", { key: "bid-".concat(index), className: "flex gap-4 items-center pl-4 pr-2 py-2\n                    ".concat(incomingBids.includes(bid.entity_id)
                        ? "bg-green-500" //
                        : index % 2 === 0
                            ? "bg-white"
                            : "bg-gray-200", "\n                  ") },
                    react_1.default.createElement("div", { className: "flex-1 font-bold text-xl" }, doshx_controls_web_1.Utilities.formatMoney({
                        value: bid.amount,
                        decimalPlaces: 2,
                    })),
                    react_1.default.createElement("div", { className: "flex-1 flex flex-row gap-1 items-center" },
                        react_1.default.createElement("div", { className: "font-bold text-sm" },
                            "#",
                            bid.bidder.paddle_number),
                        react_1.default.createElement(react_1.default.Fragment, null, "-"),
                        react_1.default.createElement("div", { className: "".concat(showRejectBackUp ? "text-xs" : "text-md font-bold", "  max-w-60 break-words") },
                            bid.user.name,
                            " ",
                            bid.user.surname)),
                    react_1.default.createElement("div", { className: "text-xs" }, (0, functions_1.formatDate)(bid.created_at, //
                    "Do MMM YYYY, h:mm A")),
                    showRejectBackUp && (react_1.default.createElement("div", { className: "flex gap-2" },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faBan, backgroundColor: "bg-red-400", size: "xsmall", borderColor: "border-transparent", tooltip: {
                                label: "Reject",
                                placement: "left",
                                colors: {
                                    backgroundColor: "bg-bluer",
                                    textColor: "text-white",
                                },
                            }, onClick: function () {
                                dispatch((0, main_1.setMessageModal)({
                                    title: "Reject Bid?",
                                    messages: ["Are you sure that you want to reject this bid?"],
                                    buttons: "yesNo",
                                    onConfirm: function () {
                                        (0, reject_bid_1.reject_bid)({
                                            entity_id: bid.entity_id,
                                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                if (response.data.success) {
                                                    dispatch((0, main_1.setMessageModal)(null));
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({
                                                        title: "Failed to reject bid!",
                                                        messages: [response.data.message],
                                                    }));
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); });
                                    },
                                }));
                            } }),
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faUpToLine, backgroundColor: "bg-gray-400", size: "xsmall", borderColor: "border-transparent", tooltip: {
                                label: "Back Up",
                                placement: "left",
                                colors: {
                                    backgroundColor: "bg-bluer",
                                    textColor: "text-white",
                                },
                            }, onClick: function () {
                                dispatch((0, main_1.setMessageModal)({
                                    title: "Back Up Bid?",
                                    messages: ["Are you sure that you want to back up this bid?"],
                                    buttons: "yesNo",
                                    onConfirm: function () {
                                        (0, back_up_bid_1.back_up_bid)({
                                            entity_id: bid.entity_id,
                                        }).then(function (response) {
                                            if (response.data.success) {
                                                dispatch((0, main_1.setMessageModal)(null));
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({
                                                    title: "Failed to back up the bid!",
                                                    messages: [response.data.message],
                                                }));
                                            }
                                        });
                                    },
                                }));
                            } })))));
            })) : (react_1.default.createElement("div", { className: "font-bold text-md p-4" }, "No one has placed any bids on this lot yet...")),
            (bids === null || bids === void 0 ? void 0 : bids.count) > 10 && react_1.default.createElement("div", { className: "px-4 py-2 text-center text-sm" }, "Note: older bids are not being shown..."))));
};
exports.BidsForLot = BidsForLot;
