"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientAdminsTab = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var admins_for_client_1 = require("../../../../api/calls/admin/admins_for_client");
var create_1 = require("../../../../api/calls/admin/create");
var delete_1 = require("../../../../api/calls/admin/delete");
var ClientAdminsTab = function (props) {
    var client_entity_id = props.client_entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)(""), adminEmail = _a[0], setAdminEmail = _a[1];
    var _b = (0, react_1.useState)(""), adminEmailError = _b[0], setAdminEmailError = _b[1];
    var _c = (0, react_1.useState)(""), adminName = _c[0], setAdminName = _c[1];
    var _d = (0, react_1.useState)(""), adminNameError = _d[0], setAdminNameError = _d[1];
    var _e = (0, react_1.useState)(""), adminSurname = _e[0], setAdminSurname = _e[1];
    var _f = (0, react_1.useState)(""), adminSurnameError = _f[0], setAdminSurnameError = _f[1];
    var _g = (0, react_1.useState)(""), adminPassword = _g[0], setAdminPassword = _g[1];
    var _h = (0, react_1.useState)(""), adminPasswordError = _h[0], setAdminPasswordError = _h[1];
    var _j = (0, react_1.useState)(false), isBusy = _j[0], setIsBusy = _j[1];
    var _k = (0, react_1.useState)([]), clientAdmins = _k[0], setClientAdmins = _k[1];
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            getAllClientAdmins();
        }
    }, [client_entity_id]);
    var getAllClientAdmins = function () {
        (0, admins_for_client_1.admins_for_client)({
            entity_id: client_entity_id,
        }).then(function (response) {
            if (response.data.success) {
                setClientAdmins(response.data.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Failed to get client admins!", messages: [response.data.message] }));
            }
        });
    };
    var validateCreateClientAdmin = function () {
        var errorCount = 0;
        setAdminEmailError("");
        setAdminNameError("");
        setAdminSurnameError("");
        setAdminPasswordError("");
        if (!adminEmail) {
            errorCount++;
            setAdminEmailError("Admin email is required.");
        }
        if (!adminName) {
            errorCount++;
            setAdminNameError("Admin name is required.");
        }
        if (!adminSurname) {
            errorCount++;
            setAdminSurnameError("Admin surname is required.");
        }
        if (!adminPassword) {
            errorCount++;
            setAdminPasswordError("Admin password is required.");
        }
        if (errorCount > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "text-xl font-bold" }, "Create an admin"),
                react_1.default.createElement("div", { className: "w-96 flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: adminName, errorText: adminNameError, onChange: function (v) { return setAdminName(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: adminSurname, errorText: adminSurnameError, onChange: function (v) { return setAdminSurname(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: adminEmail, errorText: adminEmailError, onChange: function (v) { return setAdminEmail(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", value: adminPassword, errorText: adminPasswordError, onChange: function (v) { return setAdminPassword(v); } })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create", 
                        // enabled={false}
                        loading: isBusy, onClick: function () {
                            if (validateCreateClientAdmin()) {
                                (0, create_1.admin_create)({
                                    name: adminName,
                                    surname: adminSurname,
                                    email: adminEmail,
                                    password: adminPassword,
                                    client_entity_id: client_entity_id,
                                }).then(function (response) {
                                    if (response.data.success) {
                                        getAllClientAdmins();
                                        setAdminName("");
                                        setAdminSurname("");
                                        setAdminEmail("");
                                        setAdminPassword("");
                                        getAllClientAdmins();
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Failed to create client admin!", messages: [response.data.message] }));
                                    }
                                });
                            }
                        } }))),
            react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "text-xl font-bold" }, "All admins for this client"),
                react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                        {
                            label: "Name",
                            displayKey: "name",
                        },
                        {
                            label: "Surname",
                            displayKey: "surname",
                        },
                        {
                            label: "Email",
                            displayKey: "email",
                        },
                    ], rowActions: [
                        {
                            label: "Delete",
                            onClick: function (row) {
                                if (row.entity_id === user.admin.entity_id) {
                                    dispatch((0, main_1.setMessageModal)({ title: "Cannot delete", messages: ["You cannot delete yourself!"] }));
                                    return;
                                }
                                dispatch((0, main_1.setMessageModal)({
                                    title: "Delete Client Admin",
                                    messages: ["Are you sure you want to delete this admin user?"],
                                    buttons: "yesNo",
                                    onConfirm: function () {
                                        (0, delete_1.admin_delete)({
                                            entity_id: row.entity_id,
                                        }).then(function (response) {
                                            if (response.data.success) {
                                                getAllClientAdmins();
                                                dispatch((0, main_1.setMessageModal)(null));
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({ title: "Failed to delete admin the user", messages: [response.data.message] }));
                                            }
                                        });
                                    },
                                }));
                            },
                        },
                    ], data: clientAdmins })))));
};
exports.ClientAdminsTab = ClientAdminsTab;
