"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.place_system_bid = void 0;
var api_1 = require("../../../api");
var place_system_bid = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/bid/place_system",
        data: req,
    });
};
exports.place_system_bid = place_system_bid;
