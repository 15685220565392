"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_client = void 0;
var api_1 = require("../../api");
var get_client = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/client/get",
        query: req,
    });
};
exports.get_client = get_client;
