"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.export_user_emails_csv = void 0;
var api_1 = require("../../api");
var export_user_emails_csv = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/client/export_user_emails_csv",
        query: req,
    });
};
exports.export_user_emails_csv = export_user_emails_csv;
