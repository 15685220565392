"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create_auction = void 0;
var api_1 = require("../../../api");
var create_auction = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/auction/create",
        data: req,
    });
};
exports.create_auction = create_auction;
