"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reject_bid = void 0;
var api_1 = require("../../../api");
var reject_bid = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/bid/reject",
        data: req,
    });
};
exports.reject_bid = reject_bid;
