"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSocketStatus = exports.setMainAuction = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    // clients: [],
    mainAuction: null,
    socketStatus: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "console",
    initialState: initialState,
    reducers: {
        setMainAuction: function (state, action) {
            state.mainAuction = action.payload;
        },
        setSocketStatus: function (state, action) {
            state.socketStatus = action.payload;
        },
    },
});
exports.setMainAuction = (_a = slice.actions, _a.setMainAuction), exports.setSocketStatus = _a.setSocketStatus;
exports.default = slice.reducer;
