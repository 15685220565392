"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bid_increments = void 0;
var api_1 = require("../../api");
var bid_increments = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/client/bid_increments",
        query: req,
    });
};
exports.bid_increments = bid_increments;
