"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomatedAdminScreen = void 0;
var socket_io_client_1 = require("socket.io-client");
var react_1 = __importStar(require("react"));
var functions_1 = require("../../../../constants/functions");
var react_redux_1 = require("react-redux");
var registered_bidders_1 = require("../common/registered_bidders");
var doshx_controls_web_1 = require("doshx_controls_web");
var countdown_1 = require("./countdown");
var get_auction_1 = require("../../../../api/calls/auction/v3/get_auction");
var react_toastify_1 = require("react-toastify");
var constants_1 = require("../../../../constants/constants");
var has_extended_lots_1 = require("../../../../api/calls/auction/v3/has_extended_lots");
var main_1 = require("../../../../reducers/slices/main");
var lots_with_bids_1 = require("./lots_with_bids");
var socket = (0, socket_io_client_1.io)((_a = process.env.API_URL) !== null && _a !== void 0 ? _a : "", {
    transports: ["websocket"],
    autoConnect: false,
});
var AutomatedAdminScreen = function (props) {
    // const bidIncrements = [1000, 10000, 100000, 1000000];
    var dispatch = (0, react_redux_1.useDispatch)();
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var admin = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user.admin;
    var playNewBidSound = (0, react_redux_1.useSelector)(function (state) { return state.soundState; }).playNewBidSound;
    var _a = (0, react_1.useState)("Inactive"), socketStatus = _a[0], setSocketStatus = _a[1];
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, react_1.useState)(true), isAuctionCompleted = _c[0], setIsAuctionCompleted = _c[1];
    var _d = (0, react_1.useState)(null), currentAuction = _d[0], setCurrentAuction = _d[1];
    (0, react_1.useEffect)(function () {
        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
            setIsLoading(true);
            getAuction(mainAuction.entity_id);
            socket.emit("joinAuctionPipe", mainAuction.entity_id);
            // Close the socket if it is already open
            if (socket.connected) {
                // socket.off("newBid");
                socket.disconnect();
            }
            socket.off("disconnect");
            socket.on("disconnect", function () {
                setSocketStatus("Inactive");
            });
            socket.off("connect");
            socket.on("connect", function () {
                setSocketStatus("Active");
                // console.log("joinoing all lots pipes", mainAuction.entity_id);
                socket.emit("joinClientPipe", selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
                socket.emit("joinAuctionPipe", mainAuction.entity_id);
            });
            socket.off("auctionStatus");
            socket.on("auctionStatus", function (data) {
                var entity_id = data.entity_id, status = data.status, has_extended_lots = data.has_extended_lots;
                console.log("auctionStatus", data);
                setCurrentAuction(function (prev) {
                    return __assign(__assign({}, prev), { status: status });
                });
                if (status == "Complete") {
                    if (has_extended_lots) {
                        (0, react_toastify_1.toast)(react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement("div", null, "Auction has completed!"),
                            react_1.default.createElement("div", null, "However, some lots has been extended!")), __assign(__assign({}, constants_1.defaultToastOptions), { onClick: function () {
                                //
                            } }));
                    }
                    else {
                        setIsAuctionCompleted(true);
                    }
                }
                else {
                }
            });
            socket.off("auctionWentLive");
            socket.on("auctionWentLive", function (data) {
                console.log("auctionWentLive", data);
                var entity_id = data.entity_id, title = data.title, description = data.description, status = data.status, type = data.type;
                //
                // getAuction(entity_id);
                (0, react_toastify_1.toast)(react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("div", null, "Auction went live!"),
                        react_1.default.createElement("div", null, title),
                        react_1.default.createElement("div", null, description),
                        react_1.default.createElement("div", null, "Click here to view the auction."))), __assign(__assign({}, constants_1.defaultToastOptions), { onClick: function () {
                        getAuction(entity_id);
                    } }));
            });
            // Connect to the socket
            socket.connect();
        }
        return function () {
            socket.disconnect();
        };
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    (0, react_1.useEffect)(function () {
        if (currentAuction) {
            var status_1 = currentAuction.status;
            if (status_1 == "Complete") {
                // has_extended_lots({
                //   auction_id: currentAuction.entity_id,
                // }).then((response) => {
                //   if (response.data.success) {
                //     const hasExtendLots = response.data.content;
                //     if (hasExtendLots) {
                //       setIsAuctionCompleted(false);
                //     } else {
                //       setIsAuctionCompleted(true);
                //     }
                //   } else {
                //     dispatch(
                //       setMessageModal({
                //         title: "Error",
                //         messages: [response.data.message],
                //       }),
                //     );
                //   }
                // });
            }
            else {
                setIsAuctionCompleted(false);
            }
        }
    }, [currentAuction]);
    function getAuction(entity_id) {
        (0, get_auction_1.get_auction)({
            entity_id: entity_id,
        }).then(function (response) {
            setIsLoading(false);
            if (response.data.success) {
                setCurrentAuction(response.data.content);
                (0, has_extended_lots_1.has_extended_lots)({
                    entity_id: entity_id,
                }).then(function (response) {
                    if (response.data.success) {
                        var has_extended_lots_2 = response.data.content;
                        if (has_extended_lots_2) {
                            setIsAuctionCompleted(false);
                        }
                        // else {
                        //   setIsAuctionCompleted(true);
                        // }
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while determining if the auction has extended lots!", messages: [response.data.message] }));
                    }
                });
            }
        });
    }
    return (react_1.default.createElement("div", { className: "flex flex-col w-full h-full p-4 space-y-4" },
        react_1.default.createElement("div", { className: "flex flex-row justify-between items-center" },
            react_1.default.createElement("div", { className: "text-4xl font-bold" }, "Automated Admin"),
            (currentAuction === null || currentAuction === void 0 ? void 0 : currentAuction.status) == "In-Progress" && react_1.default.createElement(countdown_1.CountdownComponent, { auction_id: currentAuction === null || currentAuction === void 0 ? void 0 : currentAuction.entity_id }),
            react_1.default.createElement("div", null,
                "Socket Status - ",
                socketStatus)),
        (currentAuction === null || currentAuction === void 0 ? void 0 : currentAuction.entity_id) ? (isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : currentAuction.status == "Upcoming" ? (react_1.default.createElement(react_1.default.Fragment, null,
            "This auction is still upcoming. Start Time:",
            " ",
            (0, functions_1.formatDate)(currentAuction.date_from, //
            "Do MMM YYYY, h:mm A"),
            " ",
            "- End Time:",
            " ",
            (0, functions_1.formatDate)(currentAuction.date_to, //
            "Do MMM YYYY, h:mm A"))) : (react_1.default.createElement("div", { className: "flex flex-col justify-center gap-4" }, isAuctionCompleted ? (react_1.default.createElement("div", null, "This auction has completed. You can redo the auction by setting it back to \"Upcoming\" as well as updating the dates and times to be a future date.")) : (react_1.default.createElement("div", { className: "flex flex-col space-y-4" },
            react_1.default.createElement("div", { className: "flex-none flex flex-row space-x-4" },
                react_1.default.createElement("div", { className: "flex-1" },
                    react_1.default.createElement(lots_with_bids_1.LotsWithBidsComponent, null)),
                react_1.default.createElement(registered_bidders_1.RegisteredBiddersComponent, null))))))) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Please select a client and/or auction to the left of this screen.", textColor: "text-black", size: "2xlarge", bold: true }))));
};
exports.AutomatedAdminScreen = AutomatedAdminScreen;
