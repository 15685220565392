"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.back_up_bid = void 0;
var api_1 = require("../../../api");
var back_up_bid = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/bid/back_up",
        data: req,
    });
};
exports.back_up_bid = back_up_bid;
