"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_csv_template = void 0;
var api_1 = require("../../../api");
var get_csv_template = function () {
    return (0, api_1.apiGet)({
        endpoint: "v3/lot/get_csv_template",
    });
};
exports.get_csv_template = get_csv_template;
