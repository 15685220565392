"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultToastOptions = exports.SUPPORTED_DOCUMENT_EXTENSIONS = exports.SUPPORTED_IMAGE_EXTENSIONS = void 0;
exports.SUPPORTED_IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".webp", ".gif"];
exports.SUPPORTED_DOCUMENT_EXTENSIONS = [".pdf"];
exports.defaultToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "bg-green-500 text-black",
};
