"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_lot_numbers = void 0;
var api_1 = require("../../../api");
var update_lot_numbers = function (request) {
    return (0, api_1.apiPatch)({
        endpoint: "v3/lot/update_lot_numbers",
        data: request,
    });
};
exports.update_lot_numbers = update_lot_numbers;
