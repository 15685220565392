"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lots_with_bids = void 0;
var api_1 = require("../../../api");
var lots_with_bids = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/lot/lots_with_bids",
        query: request,
    });
};
exports.lots_with_bids = lots_with_bids;
