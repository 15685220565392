"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsEditing = exports.setSelectedLot = exports.setLots = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var slice = (0, toolkit_1.createSlice)({
    name: "lot",
    initialState: {
        lots: [],
        isEditing: false,
        selectedLot: {},
    },
    reducers: {
        setIsEditing: function (state, action) {
            state.isEditing = action.payload;
        },
        setLots: function (state, action) {
            state.lots = action.payload;
        },
        setSelectedLot: function (state, action) {
            state.selectedLot = action.payload;
        },
    },
});
exports.setLots = (_a = slice.actions, _a.setLots), exports.setSelectedLot = _a.setSelectedLot, exports.setIsEditing = _a.setIsEditing;
exports.default = slice.reducer;
