"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_all_clients = void 0;
var api_1 = require("../../api");
var get_all_clients = function () {
    return (0, api_1.apiGet)({
        endpoint: "v3/client/get_all",
    });
};
exports.get_all_clients = get_all_clients;
