"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.save_social_media = void 0;
var api_1 = require("../../api");
var save_social_media = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/client/save_social_media",
        data: req,
    });
};
exports.save_social_media = save_social_media;
