"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bids_for_lot = void 0;
var api_1 = require("../../../api");
var bids_for_lot = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/bid/bids_for_lot",
        query: req,
    });
};
exports.bids_for_lot = bids_for_lot;
