"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_configuration = void 0;
var api_1 = require("../../api");
var get_configuration = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/configuration/get",
        // data: formData,
        query: request,
    });
};
exports.get_configuration = get_configuration;
