"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.has_extended_lots = void 0;
var api_1 = require("../../../api");
var has_extended_lots = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/auction/has_extended_lots",
        query: req,
    });
};
exports.has_extended_lots = has_extended_lots;
