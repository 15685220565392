"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.admin_create = void 0;
var api_1 = require("../../api");
var admin_create = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v3/admin/create",
        data: __assign(__assign({}, request), { role: "admin" }),
    });
};
exports.admin_create = admin_create;
