"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleUploadControl = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("../../constants/constants");
var functions_1 = require("../../constants/functions");
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var upload_1 = require("../../api/calls/file/upload");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var delete_1 = require("../../api/calls/file/delete");
var get_bidder_1 = require("../../api/calls/bidder/v3/get_bidder");
// Sorry at the moemnt we mostly only supprot bidders, the rest needs implementations.
var SingleUploadControl = function (props) {
    var auction_entity_id = props.auction_entity_id, lot_entity_id = props.lot_entity_id, user_entity_id = props.user_entity_id, bidder_entity_id = props.bidder_entity_id, custom_name = props.custom_name, type = props.type;
    console.log("props", props);
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(), file = _a[0], setFile = _a[1];
    var _b = (0, react_1.useState)(-1), uploadProgress = _b[0], setUploadProgress = _b[1];
    var _c = (0, react_1.useState)(null), auction = _c[0], setAuction = _c[1];
    var _d = (0, react_1.useState)(null), lot = _d[0], setLot = _d[1];
    var fileInputRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (bidder_entity_id) {
            (0, get_bidder_1.get_bidder)({
                entity_id: bidder_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    // setAuction(response.data.content);
                    console.log("response.data.content", response.data.content);
                    // switch (type) {
                    //   case "Image":
                    //     loadFile(response.data.content.images);
                    //     break;
                    //   case "Document":
                    //     loadFile(response.data.content.documents);
                    //     break;
                    // }
                    loadFile(response.data.content[custom_name !== null && custom_name !== void 0 ? custom_name : "null"]);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                }
            });
        }
        // if (auction_entity_id) {
        //   get_auction({
        //     entity_id: auction_entity_id,
        //   }).then((response) => {
        //     if (response.data.success) {
        //       // setAuction(response.data.content);
        //       console.log("response.data.content", response.data.content);
        //       switch (type) {
        //         case "Image":
        //           loadFiles(response.data.content.images);
        //           break;
        //         case "Document":
        //           loadFiles(response.data.content.documents);
        //           break;
        //       }
        //     } else {
        //       dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.data.message] }));
        //     }
        //   });
        // }
        // if (lot_entity_id) {
        //   get_lot({
        //     entity_id: lot_entity_id,
        //   }).then((response) => {
        //     if (response.data.success) {
        //       setLot(response.data.content);
        //       console.log("response.data.contena sd ad t", response.data.content);
        //       switch (type) {
        //         case "Image":
        //           loadFiles(response.data.content.images);
        //           break;
        //         case "Document":
        //           loadFiles(response.data.content.documents);
        //           break;
        //       }
        //     } else {
        //       dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.data.message] }));
        //     }
        //   });
        // }
        // if (bidder_entity_id) {
        //   retrieve_file({
        //     bidder_entity_id: bidder_entity_id,
        //   }).then((response) => {
        //     if (response.data.success) {
        //       // setBidderDocuments(response.data.content);
        //       console.log("response.data.content", response.data.content);
        //       loadFiles(response.data.content);
        //     } else {
        //       dispatch(setMessageModal({ title: "Something went wrong while getting bidder documents!", messages: [response.data.message] }));
        //     }
        //   });
        // }
        // if (user_entity_id) {
        //   // TODO
        // }
    }, []);
    var loadFile = function (fle) { return __awaiter(void 0, void 0, void 0, function () {
        var file_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("fle", fle);
                    if (!fle) return [3 /*break*/, 2];
                    if (!fle.uploaded_file_url) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, functions_1.urlToFile)(fle.uploaded_file_url)];
                case 1:
                    file_1 = _a.sent();
                    setFile(__assign(__assign({}, fle), { file: file_1 }));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleFile = function (f) { return __awaiter(void 0, void 0, void 0, function () {
        var fObj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fObj = {
                        // uuid: uploadUid,
                        custom_name: custom_name,
                        auction_id: auction_entity_id,
                        lot_id: lot_entity_id,
                        user_id: user_entity_id,
                        bidder_id: bidder_entity_id,
                        type: type,
                        file: f,
                    };
                    setUploadProgress(0);
                    setFile(fObj);
                    return [4 /*yield*/, (0, upload_1.upload_file)({
                            auction_id: auction_entity_id,
                            lot_id: lot_entity_id,
                            bidder_id: bidder_entity_id,
                            type: type,
                            file: f,
                            custom_name: custom_name,
                            uploadProgress: function (percentCompleted) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    console.log("percentCompleted", percentCompleted);
                                    setUploadProgress(percentCompleted);
                                    return [2 /*return*/];
                                });
                            }); },
                        }).then(function (response) {
                            console.log("response", response);
                            if (response.data.success) {
                                fObj.entity_id = response.data.content.entity_id;
                                fObj.uploaded_file_url = response.data.content.uploaded_file_url;
                                // setFile();
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteFile = function (file_id) {
        (0, delete_1.delete_file)({ file_entity_id: file_id }).then(function (response) {
            if (response.data.success) {
                fileInputRef.current.value = "";
                setFile(undefined);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while deleting a single file!", messages: [response.data.message] }));
            }
        });
    };
    var DragHere = function () {
        return (react_1.default.createElement("div", { className: "border-4 border-dashed \n        border-black text-black \n        text-2xl w-full rounded-xl p-4 \n        cursor-pointer text-center hover:underline", onClick: function () {
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
            }, onDragOver: function (e) {
                e.preventDefault();
            }, onDrop: function (e) {
                e.preventDefault();
                handleFile(e.dataTransfer.files[0]);
            } }, type === "Image" ? "Drag one image here, or click to upload" : "Drag one document here, or click to upload"));
    };
    var bytesToMB = function (bytes) {
        return (bytes / (1024 * 1024)).toFixed(2);
    };
    var allExtensions = constants_1.SUPPORTED_IMAGE_EXTENSIONS.concat(constants_1.SUPPORTED_DOCUMENT_EXTENSIONS);
    return (react_1.default.createElement("div", { className: "flex flex-col w-full min-h-[100px]" },
        react_1.default.createElement("div", { className: "w-full" },
            react_1.default.createElement("input", { ref: fileInputRef, className: "hidden", type: "file" //
                , 
                // accept={type === "Image" ? SUPPORTED_IMAGE_EXTENSIONS.join(",") : SUPPORTED_DOCUMENT_EXTENSIONS.join(",")}
                accept: allExtensions.join(","), multiple: false, onChange: function (e) {
                    if (e.target.files) {
                        handleFile(e.target.files[0]);
                    }
                } }),
            !file ? (react_1.default.createElement(DragHere, null)) : (react_1.default.createElement("div", { className: "relative mt-2 flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "z-10 flex flex-row gap-4 flex-wrap" }, (function () {
                    switch (type) {
                        case "Image":
                            return (react_1.default.createElement("div", { className: "relative w-44 h-44 flex flex-col" },
                                react_1.default.createElement("img", { className: "w-full h-full object-cover ".concat(file.uploaded_file_url ? "rounded-lg" : "rounded-t-lg"), src: URL.createObjectURL(file.file) }),
                                !file.uploaded_file_url && (react_1.default.createElement("div", { className: "h-2 w-full rounded-b-lg overflow-clip" },
                                    react_1.default.createElement("div", { className: "bg-red-400 h-full", style: {
                                            width: "".concat(uploadProgress[file.file.name], "%"),
                                        } }))),
                                react_1.default.createElement("div", { className: "w-8 h-8 rounded-full bg-red-500 text-white text-sm absolute \n                            -top-3 -right-3 flex justify-center items-center cursor-pointer", onClick: function () {
                                        deleteFile(file.entity_id);
                                    } },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes }))));
                        case "Document":
                            return (react_1.default.createElement("div", { className: "relative  " },
                                react_1.default.createElement("div", { className: "w-8 h-8 rounded-full bg-red-500 text-white text-sm absolute \n                            -top-3 -right-3 flex justify-center items-center cursor-pointer", onClick: function () {
                                        deleteFile(file.entity_id);
                                    } },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes })),
                                react_1.default.createElement("div", { className: "text-md text-white bg-bluish \n                            ".concat(file.uploaded_file_url ? "rounded-lg" : "rounded-t-lg", " \n                            p-3 cursor-pointer"), onClick: function () {
                                        dispatch((0, main_1.setContentModal)({
                                            title: file.file.name,
                                            content: (react_1.default.createElement("div", { style: {
                                                    width: "800px",
                                                    height: "600px",
                                                } },
                                                react_1.default.createElement("div", { style: {
                                                        // width: "100px",
                                                        // height: "100px",
                                                        backgroundColor: "transparent",
                                                    }, className: "w-full h-full" }, (function () {
                                                    var _a;
                                                    var allExtensions = constants_1.SUPPORTED_IMAGE_EXTENSIONS.concat(constants_1.SUPPORTED_DOCUMENT_EXTENSIONS);
                                                    var ext = (_a = file.file.name.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase();
                                                    if (allExtensions.includes(".".concat(ext))) {
                                                        return (react_1.default.createElement("object", { data: file.uploaded_file_url, 
                                                            // type="application/pdf"
                                                            className: "w-full h-full" },
                                                            react_1.default.createElement("div", { className: "flex flex-row justify-center items-center h-full" },
                                                                "It appears you don't have a plugin for this browser to view this file. No worries, you can",
                                                                react_1.default.createElement("a", { href: file.uploaded_file_url, target: "_blank", className: "hover:underline cursor-pointer" }, "click here"),
                                                                "to download the file.")));
                                                    }
                                                    else {
                                                        return (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center h-full" },
                                                            react_1.default.createElement("div", { className: "font-bold" }, "Unsupported document."),
                                                            react_1.default.createElement("a", { href: file.uploaded_file_url, target: "_blank", className: "hover:underline cursor-pointer" },
                                                                "Download ",
                                                                file.file.name)));
                                                    }
                                                })()))),
                                        }));
                                    } },
                                    file.file.name,
                                    " - ",
                                    bytesToMB(file.file.size),
                                    "MB"),
                                !file.uploaded_file_url && (react_1.default.createElement("div", { className: "h-2 w-full rounded-b-lg overflow-clip" },
                                    react_1.default.createElement("div", { className: "bg-red-400 h-full", style: {
                                            width: "".concat(uploadProgress[file.file.name], "%"),
                                        } })))));
                    }
                })()))))));
};
exports.SingleUploadControl = SingleUploadControl;
