"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageModalControl = void 0;
var react_1 = __importStar(require("react"));
var modal_1 = require("./modal");
var MessageModalControl = function (props) {
    var _a = props !== null && props !== void 0 ? props : {}, title = _a.title, close = _a.close, isOpen = _a.isOpen, messages = _a.messages, buttons = _a.buttons;
    var _b = close !== null && close !== void 0 ? close : {}, showClose = _b.showClose, onClose = _b.onClose;
    var _c = (0, react_1.useState)(isOpen), visible = _c[0], setVisible = _c[1];
    (0, react_1.useEffect)(function () {
        setVisible(isOpen);
    }, [isOpen]);
    return (react_1.default.createElement(modal_1.ModalControl, { title: title, isOpen: visible, close: {
            showClose: showClose,
            onClose: onClose,
        } },
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement("div", { className: "pt-4" }, messages.map(function (message, index) {
                return (react_1.default.createElement("div", { key: index, className: "text-white text-lg" }, message));
            })),
            buttons && (react_1.default.createElement("div", { className: "flex flex-row gap-4" }, buttons.map(function (button, index) {
                return button;
            }))))));
};
exports.MessageModalControl = MessageModalControl;
