"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create_configuration = void 0;
var store_1 = require("../../store");
var api_1 = require("../api");
var create_configuration = function (request) {
    var _a = store_1.redux_store.getState(), _b = _a.userState.user, role = _b.admin.role, client_token = _b.client_token, selectedClient = _a.clientState.selectedClient;
    return (0, api_1.apiPost)({
        endpoint: "v2/config/create_configuration",
        data: request,
        customHeaders: {
            client_token: role == "super_admin" ? selectedClient.token : client_token,
        },
    });
};
exports.create_configuration = create_configuration;
