"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.register_bidder = void 0;
var api_1 = require("../../../api");
var register_bidder = function (req) {
    return (0, api_1.apiPost)({
        endpoint: "v3/bidder/register",
        data: req,
    });
};
exports.register_bidder = register_bidder;
