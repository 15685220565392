"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateNestedProperty = void 0;
var updateNestedProperty = function (obj, path, value) {
    var keys = path.split(".");
    var lastKey = keys.pop();
    var deepClone = __assign({}, obj);
    var current = deepClone;
    keys.forEach(function (key) {
        if (!current[key]) {
            current[key] = {};
        }
        current = current[key];
    });
    if (lastKey) {
        current[lastKey] = value;
    }
    return deepClone;
};
exports.updateNestedProperty = updateNestedProperty;
