"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBidderScreen = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var unregistered_bidders_for_client_1 = require("../../../api/calls/bidder/v3/unregistered_bidders_for_client");
var register_bidder_1 = require("../../../api/calls/bidder/v3/register_bidder");
var RegisterBidderScreen = function (props) {
    var refreshUsers = props.refreshUsers;
    var dispatch = (0, react_redux_1.useDispatch)();
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _a = (0, react_1.useState)([]), registeredUsers = _a[0], setRegisteredUsers = _a[1];
    var _b = (0, react_1.useState)(), selectedUser = _b[0], setSelectedUser = _b[1];
    var _c = (0, react_1.useState)(""), idNumber = _c[0], setIDNumber = _c[1];
    var _d = (0, react_1.useState)(""), address = _d[0], setAddress = _d[1];
    var _e = (0, react_1.useState)(false), isLoading = _e[0], setIsLoading = _e[1];
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            (0, unregistered_bidders_for_client_1.unregistered_bidders_for_client)({
                client_entity_id: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    console.log("response.data.content", response.data.content);
                    setRegisteredUsers(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                }
            });
        }
    }, [selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { options: registeredUsers, renderDisplay: function (v) {
                return "".concat(v.name, " ").concat(v.surname);
            }, label: "Select Unregistered User", value: selectedUser, onChange: function (v) {
                console.log("DropdownSearchControl onChange", v);
                setSelectedUser(v);
            } }),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "ID Number", value: idNumber, onChange: function (v) {
                    setIDNumber(v);
                } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Address", value: address, onChange: function (v) {
                    setAddress(v);
                } })),
        react_1.default.createElement("div", null,
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Register Bidder", loading: isLoading, onClick: function () {
                    setIsLoading(true);
                    (0, register_bidder_1.register_bidder)({
                        id_number: idNumber,
                        address: address,
                        bidder: {
                            client_entity_id: selectedClient.entity_id,
                            user_entity_id: selectedUser.entity_id,
                        },
                    }).then(function (response) {
                        setIsLoading(false);
                        if (response.data.success) {
                            console.log("response.data.content", response.data.content);
                            refreshUsers();
                            dispatch((0, main_1.setContentModal)(null));
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.data.message] }));
                        }
                    });
                } }))));
};
exports.RegisterBidderScreen = RegisterBidderScreen;
