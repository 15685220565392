"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialMediaTab = void 0;
var react_1 = __importStar(require("react"));
var get_social_media_1 = require("../../../../api/calls/client/get_social_media");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var doshx_controls_web_1 = require("doshx_controls_web");
var delete_social_media_1 = require("../../../../api/calls/client/delete_social_media");
var save_social_media_1 = require("../../../../api/calls/client/save_social_media");
var SocialMediaTab = function (props) {
    var client_entity_id = props.client_entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), social_media = _a[0], setSocialMedia = _a[1];
    var drops = [
        { name: "Facebook", value: "facebook" },
        { name: "Youtube", value: "youtube" },
        { name: "Linkedin", value: "linkedin" },
        { name: "Instagram", value: "instagram" },
        { name: "Twitter", value: "twitter" },
        { name: "Tiktok", value: "tiktok" },
        { name: "Whatsapp", value: "whatsapp" },
    ];
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            (0, get_social_media_1.get_social_media)({
                client_entity_id: client_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    setSocialMedia(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Failed to get social media!", messages: [response.data.message] }));
                }
            });
        }
    }, [client_entity_id]);
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save Social Media", backgroundColor: "bg-green-500", size: "small", onClick: function () {
                    var req = {
                        client_entity_id: client_entity_id,
                        social_media: social_media.map(function (x) {
                            return {
                                platform: x.platform,
                                url: x.url,
                            };
                        }),
                    };
                    (0, save_social_media_1.save_social_media)(req).then(function (response) {
                        console.log("response", response);
                        if (response.data.success) {
                            // setSocialMedia(response.data.content);
                            dispatch((0, main_1.setMessageModal)({
                                title: "Success!",
                                messages: [response.data.message],
                            }));
                        }
                        else {
                            dispatch((0, main_1.setMessageModal)({ title: "Failed to save social media!", messages: [response.data.message] }));
                        }
                    });
                } }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add", backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                    setSocialMedia(__spreadArray(__spreadArray([], social_media, true), [{ platform: "", url: "" }], false));
                } })),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" }, social_media && social_media.length > 0 ? (social_media.map(function (media, index) { return (react_1.default.createElement("div", { key: "social-media-".concat(index), className: "flex flex-row gap-2" },
            react_1.default.createElement("div", { className: "w-56" },
                react_1.default.createElement(doshx_controls_web_1.NewDropdownControl, { label: "Platform", value: drops.find(function (x) { return x.value === media.platform; }), renderDisplay: function (x) {
                        return x.name;
                    }, options: drops, onChange: function (x) {
                        setSocialMedia(__spreadArray(__spreadArray(__spreadArray([], social_media.slice(0, index), true), [__assign(__assign({}, media), { platform: x.value })], false), social_media.slice(index + 1), true));
                    }, colors: {
                        borderColor: "border-black",
                        labelColor: "text-black",
                    } })),
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "URL", value: media.url, onChange: function (v) {
                        setSocialMedia(__spreadArray(__spreadArray(__spreadArray([], social_media.slice(0, index), true), [__assign(__assign({}, media), { url: v })], false), social_media.slice(index + 1), true));
                    } })),
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete", backgroundColor: "bg-red-500", size: "small", onClick: function () {
                        if (!media.entity_id) {
                            setSocialMedia(__spreadArray(__spreadArray([], social_media.slice(0, index), true), social_media.slice(index + 1), true));
                        }
                        else {
                            (0, delete_social_media_1.delete_social_media)({
                                entity_id: media.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    setSocialMedia(__spreadArray(__spreadArray([], social_media.slice(0, index), true), social_media.slice(index + 1), true));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Failed to delete social media!", messages: [response.data.message] }));
                                }
                            });
                        }
                    } })))); })) : (react_1.default.createElement("div", null, "No social media added yet.")))));
};
exports.SocialMediaTab = SocialMediaTab;
