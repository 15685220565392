"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveBiddersComponent = void 0;
var react_1 = __importStar(require("react"));
var functions_1 = require("../../../../constants/functions");
var active_bidders_for_lot_1 = require("../../../../api/calls/bidder/v3/active_bidders_for_lot");
var socket_1 = require("../../../../socket");
var ActiveBiddersComponent = function (props) {
    var lot_entity_id = props.lot_entity_id, newBid = props.newBid;
    var _a = (0, react_1.useState)([]), activeUsers = _a[0], setActiveUsers = _a[1];
    (0, react_1.useEffect)(function () {
        if (newBid) {
            console.log("newBid active bidders", newBid);
            var bid = newBid.bid, count = newBid.count;
            getActiveBiddersForLot(lot_entity_id);
        }
    }, [newBid]);
    (0, react_1.useEffect)(function () {
        if (lot_entity_id) {
            getActiveBiddersForLot(lot_entity_id);
            socket_1.socket.off("rejectBid");
            socket_1.socket.on("rejectBid", function (data) {
                console.log("rejectBid", data);
                var lot_entity_id = data.lot_entity_id, bid_entity_id = data.bid_entity_id;
                getActiveBiddersForLot(lot_entity_id);
            });
            socket_1.socket.off("backUpBids");
            socket_1.socket.on("backUpBids", function (data) {
                console.log("backUpBids", data);
                var lot_entity_id = data.lot_entity_id, bid_entity_ids = data.bid_entity_ids;
                getActiveBiddersForLot(lot_entity_id);
            });
        }
    }, [lot_entity_id]);
    var getActiveBiddersForLot = function (lot_id) {
        (0, active_bidders_for_lot_1.active_bidders_for_lot)({
            lot_entity_id: lot_id,
        }).then(function (response) {
            if (response.data.success) {
                var registeredUsers = response.data.content;
                setActiveUsers(registeredUsers);
            }
            else {
                console.log("response.data.message", response.data.message);
            }
        });
    };
    return (react_1.default.createElement("div", { className: "w-navigation-side flex flex-col" },
        react_1.default.createElement("div", { className: "bg-[#1C77F8] p-2 rounded-t-lg text-white text-center flex flex-row space-x-2" },
            react_1.default.createElement("div", null, "ACTIVE BIDDERS"),
            react_1.default.createElement("div", { className: "text-sm" })),
        react_1.default.createElement("div", { className: "flex flex-col divide-y rounded-b-lg overflow-hidden shadow-lg" }, activeUsers.map(function (activeUser, index) {
            return (react_1.default.createElement("div", { key: "registered-user-".concat(index), className: "flex gap-4 justify-between" },
                react_1.default.createElement("div", { className: "bg-white p-2 hover:bg-gray-100 transition" },
                    (0, functions_1.capitalizeFirstLetter)(activeUser.user.name),
                    " ",
                    (0, functions_1.capitalizeFirstLetter)(activeUser.user.surname)),
                react_1.default.createElement("div", { className: "flex items-center pr-4" })));
        }))));
};
exports.ActiveBiddersComponent = ActiveBiddersComponent;
