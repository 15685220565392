"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_auction = void 0;
var api_1 = require("../../../api");
var get_auction = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/auction/get",
        // data: formData,
        query: req,
    });
};
exports.get_auction = get_auction;
