"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientsScreen = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var get_all_clients_1 = require("../../../api/calls/client/get_all_clients");
var colors_1 = require("../../../constants/colors");
var copy_button_1 = require("../../../controls/copybutton/copy_button");
var react_router_dom_1 = require("react-router-dom");
var client_1 = require("./client");
var client_2 = require("../../../reducers/slices/client");
var ClientsScreen = function (props) {
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    console.log("selectedClient", selectedClient);
    var _a = (0, react_1.useState)([]), clients = _a[0], setClients = _a[1];
    (0, react_1.useEffect)(function () {
        getAllClients();
    }, []);
    var getAllClients = function () {
        (0, get_all_clients_1.get_all_clients)().then(function (response) {
            if (response.data.success) {
                setClients(response.data.content);
                dispatch((0, client_2.setClients)(response.data.content));
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Failed to get all clients!", messages: [response.data.message] }));
            }
        });
    };
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement(react_router_dom_1.Routes, null,
            react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", { className: "flex flex-row" },
                        react_1.default.createElement("div", { className: "flex justify-start flex-1 text-2xl font-bold" }, "All Clients")),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create a New Client", backgroundColor: "bg-green-500", size: "small", onClick: function () {
                                navigate("".concat(pathname, "/client"));
                            } }),
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Refresh List", backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                                getAllClients();
                            } })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                                {
                                    label: "Name",
                                    displayKey: "name",
                                },
                                {
                                    label: "Has Bid Increments?",
                                    displayKey: "",
                                    formatter: function (row) {
                                        // row.bid_increments must be truthy, and be > 0 in length
                                        return row.bid_increments && row.bid_increments.length > 0 ? (react_1.default.createElement("span", { className: "text-green-600 font-bold" }, "Yes")) : (react_1.default.createElement("span", { className: "text-red-500 font-bold" }, "No"));
                                    },
                                },
                                {
                                    label: "Token",
                                    displayKey: "",
                                    formatter: function (row) {
                                        return (react_1.default.createElement("div", { className: "flex gap-2 items-center" },
                                            react_1.default.createElement(copy_button_1.CopyButtonControl, { value: row.token }),
                                            react_1.default.createElement("span", null,
                                                "...",
                                                row.token.substring(row.token.length - 30))));
                                    },
                                },
                            ], data: clients, colors: colors_1.tableColors, onRowClicked: function (row) {
                                navigate("".concat(pathname, "/client/").concat(row.entity_id));
                            } }))) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "/client/:client_entity_id?", element: react_1.default.createElement(client_1.ClientDetailsScreen, { refreshClientList: function () {
                        getAllClients();
                    } }) }))));
};
exports.ClientsScreen = ClientsScreen;
