"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_user = void 0;
var api_1 = require("../../../api");
var get_user = function (request) {
    return (0, api_1.apiGet)({
        endpoint: "v3/user/get",
        query: request,
    });
};
exports.get_user = get_user;
