"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bidder_verification = void 0;
var api_1 = require("../../../api");
var bidder_verification = function (req) {
    return (0, api_1.apiPut)({
        endpoint: "v3/bidder/verification",
        data: req,
    });
};
exports.bidder_verification = bidder_verification;
