"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sign_up = void 0;
var api_1 = require("../api");
var sign_up = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v2/admin/sign_up",
        data: request,
    });
};
exports.sign_up = sign_up;
