"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotsForAuction = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var lots_for_auction_1 = require("../../../../../api/calls/lot/v3/lots_for_auction");
var manual_set_current_1 = require("../../../../../api/calls/lot/v3/manual_set_current");
var main_1 = require("../../../../../reducers/slices/main");
var lot_status_1 = require("../../common/lot_status");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var socket_1 = require("../../../../../socket");
var manual_lot_status_1 = require("../../../../../api/calls/lot/v3/manual_lot_status");
var LotsForAuction = function (props) {
    var _a;
    var currentLot = props.currentLot;
    var dispatch = (0, react_redux_1.useDispatch)();
    var filterRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(null), filterValue = _b[0], setFilterValue = _b[1];
    var _c = (0, react_1.useState)(null), lots = _c[0], setLots = _c[1];
    var _d = (0, react_1.useState)(null), current = _d[0], setCurrent = _d[1];
    var _e = (0, react_1.useState)(null), statusChanged = _e[0], setStatusChanged = _e[1];
    var _f = (0, react_1.useState)(null), selectedFilter = _f[0], setSelectedFilter = _f[1];
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    (0, react_1.useEffect)(function () {
        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
            (0, lots_for_auction_1.lots_for_auction)({
                auction_entity_id: mainAuction.entity_id,
            }).then(function (response) {
                console.log("response", response);
                setLots(response.data.content);
            });
        }
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    (0, react_1.useEffect)(function () {
        if (currentLot) {
            setCurrent(currentLot);
            // socket.emit("joinLotPipe", currentLot.lot_entity_id);
        }
    }, [currentLot]);
    (0, react_1.useEffect)(function () {
        if (statusChanged) {
            var lot_entity_id_1 = statusChanged.lot_entity_id, auction_entity_id = statusChanged.auction_entity_id, title = statusChanged.title, status_1 = statusChanged.status, type = statusChanged.type, highest_bid = statusChanged.highest_bid;
            var foundLot = lots.find(function (lot) { return lot.entity_id === lot_entity_id_1; });
            console.log("foundLot", statusChanged);
            // setsate
            if (foundLot) {
                foundLot.status = status_1;
                setLots(__spreadArray([], lots, true));
            }
        }
    }, [statusChanged]);
    (0, react_1.useEffect)(function () {
        socket_1.socket.on("lotStatusForAuction", function (data) {
            setStatusChanged(data);
        });
        return function () {
            socket_1.socket.off("lotStatusForAuction");
        };
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col border-bluer border-4 rounded-xl overflow-clip" },
        react_1.default.createElement("div", { className: "flex justify-between items-center bg-bluer" },
            react_1.default.createElement("div", { className: "py-2 flex gap-2" },
                react_1.default.createElement("span", { className: "pl-2 text-white text-xl font-bold " }, "LOTS")),
            react_1.default.createElement("div", { className: "mr-1 flex gap-4 items-center" },
                react_1.default.createElement(doshx_controls_web_1.PopoverControl, { content: react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex justify-between" },
                            react_1.default.createElement("div", { className: "font-bold" }, "Filter by..."),
                            react_1.default.createElement("div", { className: "cursor-pointer text-sm text-red-600", onClick: function () {
                                    setSelectedFilter(null);
                                } }, "Clear filter")),
                        react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { selectedValue: selectedFilter, onChange: function (value) {
                                setSelectedFilter(value);
                            }, options: [
                                { label: "Bidding Open", value: "Bidding Open" },
                                { label: "Sold", value: "Sold" },
                                { label: "Passed", value: "Passed" },
                                { label: "Withdrawn", value: "Withdrawn" },
                            ] })) },
                    react_1.default.createElement("div", { className: "flex bg-white p-2 rounded-md cursor-pointer" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faAlignJustify }))),
                react_1.default.createElement("div", { className: "flex flex-col items-center" },
                    react_1.default.createElement("div", { className: "text-white text-sm font-bold" }, lots === null || lots === void 0 ? void 0 : lots.length),
                    react_1.default.createElement("div", { className: "text-white text-xs" }, "TOTAL LOTS")),
                react_1.default.createElement("div", { className: "flex gap-2 h-8 bg-white rounded-lg overflow-clip" },
                    react_1.default.createElement("div", { className: "flex items-center cursor-pointer pl-2 text-bluer", onClick: function () {
                            filterRef.current.focus();
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag })),
                    react_1.default.createElement("input", { ref: filterRef, type: "number", min: 1, max: lots === null || lots === void 0 ? void 0 : lots.length, className: "w-14 bg-inherit border-black outline-none text-lg font-bold", onChange: function (e) {
                            setFilterValue(e.target.value);
                        } })))),
        react_1.default.createElement("div", { className: "flex flex-col" }, (_a = (lots !== null && lots !== void 0 ? lots : [])
            .filter(function (x) {
            if (!filterValue) {
                return true;
            }
            return x.lot_number == filterValue;
        })
            .filter(function (x) {
            if (!selectedFilter) {
                return true;
            }
            return x.status == selectedFilter;
        })) === null || _a === void 0 ? void 0 : _a.map(function (lot, index) {
            var isCurrentLot = (current === null || current === void 0 ? void 0 : current.lot_number) === lot.lot_number;
            return (react_1.default.createElement("div", { key: "lot-".concat(index), className: "flex items-center gap-4 py-2 px-2\n                  ".concat(!isCurrentLot && "hover:bg-gray-200", " transition group\n                  ").concat(isCurrentLot && "bg-green-300", "\n                ") },
                react_1.default.createElement("div", { className: "flex-1 flex gap-4 items-center" },
                    react_1.default.createElement("div", { className: "flex items-center gap-1 min-w-10" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag, className: "text-sm" }),
                        react_1.default.createElement("span", { className: "font-bold text-lg" }, lot.lot_number)),
                    react_1.default.createElement("div", { className: "flex-1" }, lot.title)),
                react_1.default.createElement("div", { className: "invisible group-hover:visible flex gap-1 items-center" },
                    react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Mark as bidding open", placement: "top" },
                        react_1.default.createElement("div", { className: "bg-orange-500 flex items-center px-2 rounded-md font-bold text-sm text-white cursor-pointer", onClick: function () {
                                console.log("HERE");
                                (0, manual_lot_status_1.manual_lot_status)({
                                    entity_id: lot.entity_id,
                                    status: "Bidding Open",
                                }).then(function (response) {
                                    if (response.data.success) {
                                        //
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Error",
                                            messages: [response.data.message],
                                        }));
                                    }
                                });
                            } }, "O")),
                    react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Mark as sold", placement: "top" },
                        react_1.default.createElement("div", { className: "bg-green-500 flex items-center px-2 rounded-md font-bold text-sm text-white cursor-pointer", onClick: function () {
                                (0, manual_lot_status_1.manual_lot_status)({
                                    entity_id: lot.entity_id,
                                    status: "Sold",
                                }).then(function (response) {
                                    if (response.data.success) {
                                        //
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Error",
                                            messages: [response.data.message],
                                        }));
                                    }
                                });
                            } }, "S")),
                    react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Mark as passed", placement: "top" },
                        react_1.default.createElement("div", { className: "bg-bluish flex items-center px-2 rounded-md font-bold text-sm text-white cursor-pointer", onClick: function () {
                                (0, manual_lot_status_1.manual_lot_status)({
                                    entity_id: lot.entity_id,
                                    status: "Passed",
                                }).then(function (response) {
                                    if (response.data.success) {
                                        //
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Error",
                                            messages: [response.data.message],
                                        }));
                                    }
                                });
                            } }, "P")),
                    react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Mark as withdrawn", placement: "top" },
                        react_1.default.createElement("div", { className: "bg-red-500 flex items-center px-1 rounded-md font-bold text-sm text-white cursor-pointer", onClick: function () {
                                console.log("HERE");
                                (0, manual_lot_status_1.manual_lot_status)({
                                    entity_id: lot.entity_id,
                                    status: "Withdrawn",
                                }).then(function (response) {
                                    if (response.data.success) {
                                        //
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Error",
                                            messages: [response.data.message],
                                        }));
                                    }
                                });
                            } }, "W"))),
                react_1.default.createElement("div", { className: "flex items-center" },
                    react_1.default.createElement(lot_status_1.LotStatus, { lot_status: lot.status })),
                isCurrentLot ? (react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Currently active", placement: "top" },
                    react_1.default.createElement("div", { className: "h-6 bg-bluish flex items-center px-2 rounded-md font-bold text-md text-white cursor-pointer", onClick: function () {
                            //
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faClock })))) : (lot.status == "Bidding Open" && (react_1.default.createElement(doshx_controls_web_1.TooltipControl, { label: "Jump to this lot", placement: "top" },
                    react_1.default.createElement("div", { className: "h-6 bg-orange-400 flex items-center px-2 rounded-md font-bold text-md text-white cursor-pointer", onClick: function () {
                            (0, manual_set_current_1.manual_set_current)({
                                auction_entity_id: mainAuction.entity_id,
                                lot_entity_id: lot.entity_id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    //
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Error",
                                        messages: [response.data.message],
                                    }));
                                }
                            });
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faArrowRightArrowLeft }))))
                // <IconButtonControl
                //   icon={faArrowRightArrowLeft}
                //   backgroundColor="bg-gray-400"
                //   size="xsmall"
                //   borderColor="border-transparent"
                //   tooltip={{
                //     label: "Jump To This Lot",
                //     placement: "left",
                //     colors: {
                //       backgroundColor: "bg-bluer",
                //       textColor: "text-white",
                //     },
                //   }}
                //   onClick={() => {
                //     manual_set_current({
                //       auction_entity_id: mainAuction.entity_id,
                //       lot_entity_id: lot.entity_id,
                //     }).then((response) => {
                //       if (response.data.success) {
                //         //
                //       } else {
                //         dispatch(
                //           setMessageModal({
                //             title: "Error",
                //             messages: [response.data.message],
                //           }),
                //         );
                //       }
                //     });
                //   }}
                // />
                )));
        }))));
};
exports.LotsForAuction = LotsForAuction;
