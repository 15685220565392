"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManualAuctioneerScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var socket_1 = require("../../../../../socket");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../../../../reducers/slices/main");
var get_auction_1 = require("../../../../../api/calls/auction/v3/get_auction");
var connection_status_1 = require("../../common/connection_status");
var previous_current_next_1 = require("./previous_current_next");
var bids_for_lot_1 = require("../common/bids_for_lot");
var active_bidders_1 = require("../../common/active_bidders");
var react_toastify_1 = require("react-toastify");
var constants_1 = require("../../../../../constants/constants");
var doshx_controls_web_1 = require("doshx_controls_web");
var ManualAuctioneerScreen = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }), mainAuction = _a.mainAuction, socketStatus = _a.socketStatus;
    var _b = (0, react_1.useState)(null), auction = _b[0], setAuction = _b[1];
    var _c = (0, react_1.useState)(null), currentLot = _c[0], setCurrentLot = _c[1];
    var _d = (0, react_1.useState)(null), newBid = _d[0], setNewBid = _d[1];
    (0, react_1.useEffect)(function () {
        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
            (0, get_auction_1.get_auction)({
                entity_id: mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    socket_1.socket.emit("joinAuctionPipe", mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                    socket_1.socket.on("connect", function () {
                        console.log("connected/reconnected to socket");
                        socket_1.socket.emit("joinAuctionPipe", mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id);
                    });
                    setAuction(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Error",
                        messages: [response.data.message],
                    }));
                }
            });
        }
        return function () {
            // socket.off("connect");
            socket_1.socket.off("newBid");
            socket_1.socket.off("auctionManualStart");
            socket_1.socket.off("auctionManualStop");
        };
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    return (react_1.default.createElement("div", { className: "flex flex-col p-4 gap-4" },
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(connection_status_1.ConnectionState, null)),
        (auction === null || auction === void 0 ? void 0 : auction.status) == "In-Progress" ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex justify-between" },
                react_1.default.createElement("div", { className: "font-bold text-2xl" }, auction.title),
                react_1.default.createElement("div", { className: "font-bold text-2xl" }, auction.status)),
            react_1.default.createElement("div", { className: "flex gap-4" },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                    react_1.default.createElement(previous_current_next_1.AuctioneerPreviousCurrentNext, { currentLotChanged: function (lot) {
                            setCurrentLot(lot);
                        }, currentBid: newBid }),
                    react_1.default.createElement(bids_for_lot_1.BidsForLot, { showRejectBackUp: false, lot_entity_id: currentLot === null || currentLot === void 0 ? void 0 : currentLot.entity_id, 
                        // showBidNotification={true}
                        newBid: function (data) {
                            var bid = data.bid, count = data.count;
                            setNewBid(data);
                            // // Play the sound
                            // if (playNewBidSound) {
                            //   try {
                            //     const audio = new Audio(bubbleAlertSoundFile);
                            //     audio.play();
                            //   } catch (error) {
                            //     console.error("Failed to play sound", error);
                            //   }
                            // }
                            (0, react_toastify_1.toast)(react_1.default.createElement("div", { className: "flex flex-col" },
                                react_1.default.createElement("div", null,
                                    "New bid for lot # ",
                                    bid.lot.lot_number,
                                    " - ",
                                    bid.lot.title),
                                react_1.default.createElement("div", null,
                                    "Amount: ",
                                    doshx_controls_web_1.Utilities.formatMoney({ value: bid.amount, decimalPlaces: 2 }))), __assign(__assign({}, constants_1.defaultToastOptions), { autoClose: 5000 }));
                        } })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(active_bidders_1.ActiveBiddersComponent, { lot_entity_id: currentLot === null || currentLot === void 0 ? void 0 : currentLot.entity_id, newBid: newBid }))))) : (react_1.default.createElement(react_1.default.Fragment, null, "This auction is not in progress"))));
};
exports.ManualAuctioneerScreen = ManualAuctioneerScreen;
