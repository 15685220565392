"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidIncrementsTab = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var client_1 = require("../../../../reducers/slices/client");
var main_1 = require("../../../../reducers/slices/main");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var get_client_1 = require("../../../../api/calls/client/get_client");
var react_router_dom_1 = require("react-router-dom");
var update_client_1 = require("../../../../api/calls/client/update_client");
var client_2 = require("../../../../reducers/slices/client");
var BidIncrementsTab = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    console.log("params", params);
    var _a = params.client_entity_id, client_entity_id = _a === void 0 ? "" : _a;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _b = (0, react_1.useState)(""), increment = _b[0], setIncrement = _b[1];
    var _c = (0, react_1.useState)(""), incrementErrorText = _c[0], setIncrementErrorText = _c[1];
    var _d = (0, react_1.useState)({
        name: "",
        entity_id: "",
        bid_increments: [],
    }), client = _d[0], setClient = _d[1];
    var _e = (0, react_1.useState)(false), isBusy = _e[0], setIsBusy = _e[1];
    (0, react_1.useEffect)(function () {
        if (client_entity_id) {
            setIsBusy(true);
            (0, get_client_1.get_client)({
                entity_id: client_entity_id,
            }).then(function (response) {
                setIsBusy(false);
                if (response.data.success) {
                    setClient(response.data.content);
                }
            });
        }
    }, [client_entity_id]);
    var validateBidIncrements = function () {
        var errorCount = 0;
        setIncrementErrorText("");
        if (client.bid_increments.length < 1) {
            errorCount++;
            setIncrementErrorText("Please add some bid increments.");
        }
        if (errorCount > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    function addIncrement() {
        if (increment && increment > 0) {
            // if it already exists
            if (client.bid_increments.indexOf(increment) > -1) {
                dispatch((0, main_1.setMessageModal)({
                    title: "Already exists",
                    messages: [
                        "Not added because this increment (".concat(doshx_controls_web_1.Utilities.thousandSeparator({
                            value: increment.toString(),
                        }), ") already exists."),
                    ],
                }));
                setIncrement("");
            }
            else {
                console.log("client.bid_increments", client.bid_increments);
                var increments = __spreadArray(__spreadArray([], client.bid_increments, true), [increment], false);
                increments.sort(function (x, y) {
                    return x - y;
                });
                setClient(__assign(__assign({}, client), { bid_increments: increments }));
                setIncrement("");
            }
        }
        else {
            dispatch((0, main_1.setMessageModal)({ title: "Invalid increment", messages: ["Please input a bid increment (number)."] }));
        }
    }
    return (react_1.default.createElement("div", { className: "p-4 flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl
            // enabled={enableSaveButton}
            , { 
                // enabled={enableSaveButton}
                label: "Save Bid Increments", size: "small", loading: isBusy, backgroundColor: "bg-green-500", onClick: function () {
                    if (validateBidIncrements()) {
                        setIsBusy(true);
                        (0, update_client_1.update_client)({
                            entity_id: client_entity_id,
                            name: client.name,
                            bid_increments: client.bid_increments,
                        }).then(function (response) {
                            setIsBusy(false);
                            if (response.data.success) {
                                setClient(response.data.content);
                                dispatch((0, client_1.get_all_clients_thunk)());
                                if (client_entity_id == (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id)) {
                                    dispatch((0, client_2.setSelectedClient)(response.data.content));
                                }
                                dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Bid increments updated successfully!"] }));
                                // onSave(response.data.content);
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Failed to update bid increments!", messages: [response.data.message] }));
                            }
                        });
                    }
                } })),
        react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center" },
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement("div", { className: "w-72" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Bid Increment", value: increment === null || increment === void 0 ? void 0 : increment.toString(), errorText: incrementErrorText, enterPressed: function () {
                            addIncrement();
                        }, onChange: function (v) {
                            // make sure this can be cast as a number
                            var num = parseFloat(v);
                            setIncrement(num);
                            if (!isNaN(num)) {
                                setIncrement(num);
                            }
                            else {
                                setIncrement("");
                            }
                        }, size: "medium" })),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl
                // icon={faPlus}
                , { 
                    // icon={faPlus}
                    label: "Add (Enter)", size: "small", onClick: function () {
                        addIncrement();
                    }, borderColor: "border-transparent", iconColor: "text-white", backgroundColor: "bg-orange-400" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" }, client.bid_increments.map(function (increment, index) {
                return (react_1.default.createElement("div", { key: "increment-".concat(index), className: "bg-bluer px-4 py-2 rounded-lg relative min-w-20 " },
                    react_1.default.createElement("div", { className: "flex justify-center items-center text-white font-bold" }, doshx_controls_web_1.Utilities.thousandSeparator({ value: increment.toString() })),
                    react_1.default.createElement("div", { className: "absolute -top-2 -right-2 rounded-full bg-red-500 w-7 h-7 flex \n                    items-center justify-center text-light cursor-pointer", onClick: function () {
                            var bid_increments = __spreadArray([], client.bid_increments, true);
                            bid_increments.splice(bid_increments.indexOf(increment), 1);
                            setClient(__assign(__assign({}, client), { bid_increments: bid_increments }));
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes }))));
            })))));
};
exports.BidIncrementsTab = BidIncrementsTab;
