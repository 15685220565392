"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidsScreen = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var material_1 = require("@mui/material");
var react_number_format_1 = require("react-number-format");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var get_all_bids_1 = require("../../../api/calls/bid/get_all_bids");
var get_registered_users_for_auction_1 = require("../../../api/calls/get_registered_users_for_auction");
var place_bid_1 = require("../../../api/calls/bid/place_bid");
var functions_1 = require("../../../constants/functions");
// import { TableControl } from "../../../controls/table/table";
var socket_io_client_1 = require("socket.io-client");
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var lots_for_auction_1 = require("../../../api/calls/lot/v3/lots_for_auction");
var NumberFormatCustom = react_1.default.forwardRef(function NumberFormatCustom(props, ref) {
    var onChange = props.onChange, other = __rest(props, ["onChange"]);
    return (react_1.default.createElement(react_number_format_1.NumericFormat //?? is this correct? it used to be NumberFormat
    , __assign({}, other, { getInputRef: ref, onValueChange: function (values) {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }, thousandSeparator: " ", decimalSeparator: ".", prefix: "R" })));
});
var socket = (0, socket_io_client_1.io)((_a = process.env.API_URL) !== null && _a !== void 0 ? _a : "", {
    transports: ["websocket"],
    autoConnect: false,
});
var BidsScreen = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var userState = (0, react_redux_1.useSelector)(function (state) { return state; }).userState;
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var _a = (0, react_1.useState)([]), lots = _a[0], setLots = _a[1];
    var _b = (0, react_1.useState)(), selectedLot = _b[0], setSelectedLot = _b[1];
    var _c = (0, react_1.useState)([]), bids = _c[0], setBids = _c[1];
    var _d = (0, react_1.useState)([]), users = _d[0], setUsers = _d[1];
    var _e = (0, react_1.useState)(), selectedUser = _e[0], setSelectedUser = _e[1];
    var _f = (0, react_1.useState)(0), bidAmount = _f[0], setBidAmount = _f[1];
    var _g = (0, react_1.useState)(false), isAddBidOpen = _g[0], setIsAddBidOpen = _g[1];
    var bidIncrements = [100, 1000, 10000, 100000];
    (0, react_1.useEffect)(function () {
        return function () {
            socket.disconnect();
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (mainAuction && mainAuction._id) {
            (0, lots_for_auction_1.lots_for_auction)(mainAuction._id).then(function (response) {
                if (response.data.success) {
                    setLots(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting lots!", messages: [response.data.message] }));
                }
            });
            (0, get_registered_users_for_auction_1.get_registered_users_for_auction)(mainAuction._id).then(function (response) {
                if (response.data.success) {
                    setUsers(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting the auction's registered users!", messages: [response.data.message] }));
                }
            });
        }
    }, [mainAuction]);
    (0, react_1.useEffect)(function () {
        if (selectedLot) {
            (0, get_all_bids_1.get_all_bids)(selectedLot._id).then(function (response) {
                if (response.data.success) {
                    setBids(response.data.content);
                    setupSocketIO(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting bids!", messages: [response.data.message] }));
                }
            });
        }
    }, [selectedLot]);
    var setupSocketIO = function (existing_bids) {
        if (socket.connected) {
            socket.off("setUserAndJoinLotPipe");
            socket.off("newBid");
            socket.disconnect();
        }
        socket.connect();
        socket.emit("setUserAndJoinLotPipe", {
            // This object MUST look like this when you send it to my API.
            user_id: userState.user.admin._id,
            auction_id: mainAuction._id,
            lot_id: selectedLot._id,
        });
        //For new incoming BIDS.
        socket.on("newBid", function (data) {
            var newBid = {
                user_name: data.user_name,
                user_surname: data.user_surname,
                amount: data.bid_amount,
            };
            existing_bids.push(newBid);
            existing_bids.sort(function (a, b) {
                return b.amount - a.amount;
            });
            setBids(__spreadArray([], existing_bids, true));
        });
        // socket.on("lotStatus", (data) => {
        //   console.log("lotStatus", data);
        // });
    };
    var validate = function (increment) {
        var _a, _b;
        if (increment === void 0) { increment = 0; }
        var messages = [];
        if (!selectedUser) {
            messages.push("User is required.");
        }
        if (parseFloat(bidAmount) + increment <= parseFloat((_b = (_a = bids[0]) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0)) {
            messages.push("Amount cannot be less than or equal to the current max bid.");
        }
        if (messages && messages.length > 0) {
            dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: messages }));
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 px-4 pt-4 w-full" },
            react_1.default.createElement(react_router_dom_1.Routes, null,
                react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement("div", { className: "flex flex-col space-y-4" },
                        react_1.default.createElement("div", { className: "text-4xl font-bold" }, "Bids"),
                        react_1.default.createElement("div", { className: "flex space-x-4" },
                            react_1.default.createElement("div", { className: "w-1/2" },
                                react_1.default.createElement(material_1.Autocomplete, { disablePortal: true, options: lots, getOptionLabel: function (option) {
                                        return option.title;
                                    }, renderInput: function (params) { return react_1.default.createElement(material_1.TextField, __assign({}, params, { label: "Search and select a lot." })); }, onChange: function (event, option) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            console.log("option", option);
                                            if (option) {
                                                setSelectedLot(option);
                                            }
                                            else {
                                                setSelectedLot(null);
                                                setBids([]);
                                            }
                                            return [2 /*return*/];
                                        });
                                    }); } }))),
                        react_1.default.createElement("div", { className: "flex justify-between" },
                            react_1.default.createElement("div", { className: "w-40" },
                                react_1.default.createElement(material_1.Button, { variant: "contained", onClick: function () {
                                        var _a;
                                        setBidAmount((_a = bids[0]) === null || _a === void 0 ? void 0 : _a.amount);
                                        setSelectedUser(null);
                                        setIsAddBidOpen(true);
                                    } }, "Add a Bid")),
                            react_1.default.createElement("div", { className: "w-40" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TableControl
                            // title="Bids"
                            , { 
                                // title="Bids"
                                columns: [
                                    {
                                        label: "Amount",
                                        displayKey: "amount",
                                        formatter: function (v) {
                                            return doshx_controls_web_1.Utilities.formatMoney({
                                                value: v.amount,
                                                decimalPlaces: 2,
                                            });
                                        },
                                    },
                                    { label: "Name", displayKey: "user_name" },
                                    { label: "Surname", displayKey: "user_surname" },
                                    { label: "Status", displayKey: "status" },
                                ], data: bids, 
                                // onSearch={(search) => {
                                //   console.log("search", search);
                                // }}
                                // pagination={{
                                //   rowsPerPage: 10,
                                //   currentPage: 1,
                                //   onPageChange: (page) => {
                                //     // setCurrentPage(page);
                                //   },
                                //   onRowsPerPageChange: (rows) => {
                                //     // setRowsPerPage(rows);
                                //   },
                                // }}
                                colors: {
                                    titleBackgroundColor: "bg-blue-600",
                                    headerBackgroundColor: "bg-bluish",
                                    headerTextColor: "text-white",
                                    ordering: {
                                        borderColor: "border-bluish",
                                    },
                                    hoverRowColor: "hover:bg-gray-200",
                                } })),
                        react_1.default.createElement(material_1.Modal, { open: isAddBidOpen, onClose: function () {
                                setIsAddBidOpen(false);
                            } },
                            react_1.default.createElement(material_1.Box, { sx: {
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    p: 4,
                                } },
                                react_1.default.createElement("div", { className: "space-y-4" },
                                    react_1.default.createElement("div", { className: "text-2xl font-bold" }, "Add a bid"),
                                    react_1.default.createElement(material_1.Autocomplete, { disablePortal: true, options: users, getOptionLabel: function (option) {
                                            return "".concat(option.user.name, " ").concat(option.user.surname);
                                        }, renderInput: function (params) { return react_1.default.createElement(material_1.TextField, __assign({}, params, { label: "Search and select a user." })); }, onChange: function (event, option) { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                console.log("option", option);
                                                if (option) {
                                                    setSelectedUser(option);
                                                }
                                                else {
                                                    setSelectedUser(null);
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); } }),
                                    react_1.default.createElement(material_1.TextField, { label: "Amount", value: bidAmount, InputProps: {
                                            inputComponent: NumberFormatCustom,
                                            // pattern: "[0-9]*",
                                        }, onChange: function (v) {
                                            // console.log("v", v);
                                            setBidAmount(v.target.value);
                                        } }),
                                    react_1.default.createElement("div", { className: "flex flex-row space-x-2" }, bidIncrements.map(function (increment, index) {
                                        return (react_1.default.createElement(material_1.Button, { key: index, size: "small", variant: "contained", startIcon: react_1.default.createElement("span", null,
                                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faPlus, size: "xs" })), onClick: function () {
                                                var _a, _b;
                                                if (validate(increment)) {
                                                    (0, place_bid_1.place_bid_for_user)({
                                                        user_id: selectedUser.user_id,
                                                        lot_id: selectedLot._id,
                                                        amount: parseFloat((_b = (_a = bids[0]) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : selectedLot.starting_price) + parseFloat(increment.toString()),
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            setIsAddBidOpen(false);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Something went wrong while placing the bid for the user!",
                                                                messages: [response.data.message],
                                                            }));
                                                        }
                                                    });
                                                }
                                            } }, (0, functions_1.addThousandSeparator)(increment)));
                                    })),
                                    react_1.default.createElement(material_1.Button, { variant: "contained", onClick: function () {
                                            if (validate()) {
                                                (0, place_bid_1.place_bid_for_user)({
                                                    user_id: selectedUser.user_id,
                                                    lot_id: selectedLot._id,
                                                    amount: bidAmount,
                                                }).then(function (response) {
                                                    if (response.data.success) {
                                                        setIsAddBidOpen(false);
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while placing the bid for the user!", messages: [response.data.message] }));
                                                    }
                                                });
                                            }
                                        } }, "Place Bid"))))) }),
                react_1.default.createElement(react_router_dom_1.Route, { path: "/details", element: react_1.default.createElement("div", null, "Details") })))));
};
exports.BidsScreen = BidsScreen;
