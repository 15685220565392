"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableColors = exports.tabColors = void 0;
exports.tabColors = {
    borderColor: "border-gray-300",
    tabs: {
        backgroundColor: "bg-bluish",
        textColor: "text-white",
        indicatorColor: "bg-orange-400",
    },
};
exports.tableColors = {
    titleBackgroundColor: "bg-blue-600",
    headerBackgroundColor: "bg-bluish",
    headerTextColor: "text-white",
    ordering: {
        borderColor: "border-bluish",
    },
    hoverRowColor: "hover:bg-gray-200",
};
