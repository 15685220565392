"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralScreen = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var get_configuration_1 = require("../../../api/calls/get_configuration");
var create_configuration_1 = require("../../../api/calls/create_configuration");
var useSelector_1 = require("react-redux/es/hooks/useSelector");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var GeneralScreen = function (props) {
    var _a, _b, _c;
    var user = (0, useSelector_1.useSelector)(function (state) { return state.userState; }).user;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _d = (0, react_1.useState)({
        home_youtube_url: "",
        disclaimer_md_text: "",
        privacy_policy_md_text: "",
        terms_conditions_md_text: "",
        contacts: { cell_number: "", email: "", whatsapp: "" },
    }), fields = _d[0], setFields = _d[1];
    var _e = (0, react_1.useState)(false), isLoading = _e[0], setIsLoading = _e[1];
    var onFieldChange = function (name, value) {
        var _a;
        setFields(__assign(__assign({}, fields), (_a = {}, _a[name] = value, _a)));
    };
    (0, react_1.useEffect)(function () {
        getConfiguration();
    }, []);
    var getConfiguration = function () {
        (0, get_configuration_1.get_configuration)().then(function (response) {
            if (response.data.success) {
                console.log("response.data.content123", response.data.content);
                setFields(__assign({}, response.data.content));
            }
        });
    };
    var validate = function () {
        var messages = [];
        if (fields.home_youtube_url.length == 0) {
            messages.push("youtube url is required.");
        }
        if (messages && messages.length > 0) {
            setMessageModalData(__assign(__assign({}, messageModalData), { title: "Validation failed!", isOpen: true, messages: messages }));
            return false;
        }
        else {
            return true;
        }
    };
    var _f = (0, react_1.useState)({
        title: "",
        isOpen: false,
        messages: [],
    }), messageModalData = _f[0], setMessageModalData = _f[1];
    var onContactFieldChange = function (name, value) {
        var _a;
        setFields(__assign(__assign({}, fields), { contacts: __assign(__assign({}, fields.contacts), (_a = {}, _a[name] = value, _a)) }));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 px-4 pt-4 w-full" },
            react_1.default.createElement("div", { className: "text-2xl" }, "General Configuration"),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "flex flex-row" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { loading: isLoading, label: "Save Configuration", onClick: function () {
                            if (validate()) {
                                setIsLoading(true);
                                (0, create_configuration_1.create_configuration)(fields).then(function (response) {
                                    setIsLoading(false);
                                    if (response.data.success) {
                                        getConfiguration();
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Create configuration failed", messages: [response.data.message] }));
                                    }
                                });
                            }
                        } })),
                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "App Home Screen Youtube URL", value: fields.home_youtube_url, onChange: function (v) { return onFieldChange("home_youtube_url", v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "App Disclaimer", lines: 5, value: fields.disclaimer_md_text, onChange: function (v) { return onFieldChange("disclaimer_md_text", v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "App Privacy Policy", lines: 5, value: fields.privacy_policy_md_text, onChange: function (v) { return onFieldChange("privacy_policy_md_text", v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "App Terms & Conditions", lines: 5, value: fields.terms_conditions_md_text, onChange: function (v) { return onFieldChange("terms_conditions_md_text", v); } }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4 w-80" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cell number", value: (_a = fields.contacts) === null || _a === void 0 ? void 0 : _a.cell_number, type: "text", onChange: function (v) { return onContactFieldChange("cell_number", v); } }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address", value: (_b = fields.contacts) === null || _b === void 0 ? void 0 : _b.email, type: "text", onChange: function (v) { return onContactFieldChange("email", v); } }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Whatsapp number", value: (_c = fields.contacts) === null || _c === void 0 ? void 0 : _c.whatsapp, type: "text", onChange: function (v) { return onContactFieldChange("whatsapp", v); } })))))));
};
exports.GeneralScreen = GeneralScreen;
