"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_auction = void 0;
var api_1 = require("../../../api");
var update_auction = function (req) {
    return (0, api_1.apiPut)({
        endpoint: "v3/auction/update",
        data: req,
    });
};
exports.update_auction = update_auction;
