"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.upload_file = void 0;
var api_1 = require("../../api");
var store_1 = require("../../../store");
var upload_file = function (request) {
    var formData = new FormData();
    formData.append("type", request.type);
    formData.append("file", request.file);
    if (request.custom_name) {
        formData.append("custom_name", request.custom_name);
    }
    if (request.auction_id) {
        formData.append("auction_entity_id", request.auction_id);
    }
    if (request.user_id) {
        formData.append("user_entity_id", request.user_id);
    }
    if (request.lot_id) {
        formData.append("lot_entity_id", request.lot_id);
    }
    if (request.bidder_id) {
        formData.append("bidder_entity_id", request.bidder_id);
    }
    var token = store_1.redux_store.getState().userState.user.token;
    return (0, api_1.apiPost)({
        endpoint: "v3/file/upload",
        data: formData,
        contentType: "multipart/form-data",
        uploadProgress: function (percentCompleted) {
            // console.log("v2/file/upload UPLOADING:", percentCompleted);
            if (request.uploadProgress) {
                request.uploadProgress(percentCompleted);
            }
        },
        authorization: token,
    });
};
exports.upload_file = upload_file;
