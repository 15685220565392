"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotImagesTab = void 0;
var react_1 = __importStar(require("react"));
var file_uploader_1 = require("../../../controls/file/file_uploader");
var doshx_controls_web_1 = require("doshx_controls_web");
var save_order_1 = require("../../../api/calls/file/save_order");
var main_1 = require("../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var LotImagesTab = function (props) {
    var lot_entity_id = props.lot_entity_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), order = _a[0], setOrder = _a[1];
    return (react_1.default.createElement("div", { className: "flex flex-col w-full p-4" },
        react_1.default.createElement("div", { className: "flex flex-row gap-4" }, order && order.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save Images Order", size: "small", backgroundColor: "bg-orange-500", onClick: function () {
                (0, save_order_1.save_order)({
                    ordered_files: order,
                }).then(function (response) {
                    console.log("response", response);
                    if (response.data.success) {
                        dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Images order saved successfully!"] }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while saving images order!", messages: [response.data.message] }));
                    }
                });
            } }))),
        lot_entity_id ? (react_1.default.createElement("div", { className: "flex flex-col space-y-4" },
            react_1.default.createElement(file_uploader_1.FileUploaderControl, { lot_entity_id: lot_entity_id, type: "Image", onOrderChange: function (order) {
                    console.log("order", order);
                    setOrder(order);
                } }))) : (react_1.default.createElement("div", { className: "flex flex-col space-y-4" }, "You should create the lot under the details tab first, before uploading images."))));
};
exports.LotImagesTab = LotImagesTab;
