"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.create_lot = void 0;
var api_1 = require("../../../api");
var create_lot = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v3/lot/create",
        data: request,
    });
};
exports.create_lot = create_lot;
