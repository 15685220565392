"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log_in = void 0;
var api_1 = require("../../api");
var log_in = function (request) {
    return (0, api_1.apiPost)({
        endpoint: "v3/admin/log_in",
        data: request,
    });
};
exports.log_in = log_in;
