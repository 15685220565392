"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_lot = void 0;
var api_1 = require("../../../api");
var update_lot = function (request) {
    return (0, api_1.apiPut)({
        endpoint: "v3/lot/update",
        data: request,
    });
};
exports.update_lot = update_lot;
