"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.active_bidders_for_lot = void 0;
var api_1 = require("../../../api");
var active_bidders_for_lot = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/bidder/active_bidders_for_lot",
        query: req,
    });
};
exports.active_bidders_for_lot = active_bidders_for_lot;
