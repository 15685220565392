"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bidders_for_client = void 0;
var api_1 = require("../../../api");
var bidders_for_client = function (req) {
    return (0, api_1.apiGet)({
        endpoint: "v3/bidder/bidders_for_client",
        query: req,
    });
};
exports.bidders_for_client = bidders_for_client;
