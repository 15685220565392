"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiddersScreen = void 0;
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var bidder_1 = require("../../../reducers/slices/bidder");
var details_1 = require("./details");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var register_bidder_1 = require("./register_bidder");
var bidders_for_client_1 = require("../../../api/calls/bidder/v3/bidders_for_client");
var delete_bidder_1 = require("../../../api/calls/bidder/v3/delete_bidder");
var colors_1 = require("../../../constants/colors");
var unverify_all_bidders_1 = require("../../../api/calls/bidder/v3/unverify_all_bidders");
var delete_all_bidders_1 = require("../../../api/calls/bidder/v3/delete_all_bidders");
var scroll_1 = require("../../../reducers/slices/scroll");
var BiddersScreen = function (props) {
    var scrollRef = props.scrollRef;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var preserved_scroll = (0, react_redux_1.useSelector)(function (state) { return state.scrollState; }).preserved_scroll;
    var _a = (0, react_1.useState)([]), registeredUsers = _a[0], setRegisteredUsers = _a[1];
    var _b = (0, react_1.useState)(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = (0, react_1.useState)([]), filteredUsers = _c[0], setFilteredUsers = _c[1];
    var _d = (0, react_1.useState)(false), showVerified = _d[0], setShowVerified = _d[1];
    var _e = (0, react_1.useState)(false), showUnverified = _e[0], setShowUnverified = _e[1];
    var _f = (0, react_1.useState)(false), showUnverifiedWithDocs = _f[0], setShowUnverifiedWithDocs = _f[1];
    (0, react_1.useEffect)(function () {
        console.log("preserved_scroll", preserved_scroll);
        return function () {
            var _a;
            // lots unmounted, lets clear the scroll position
            console.log("bidders unmounted");
            dispatch((0, scroll_1.setPreservedScroll)(__assign(__assign({}, preserved_scroll), (_a = {}, _a[pathname] = null, _a))));
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            getRegisteredUsers(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
        }
    }, [selectedClient]);
    (0, react_1.useEffect)(function () {
        if (searchValue) {
            var filteredUsers_1 = registeredUsers.filter(function (bidder) {
                var _a, _b;
                var combinedString = "".concat(bidder.user.name, " ").concat(bidder.user.surname, " ").concat((_a = bidder.user.cell_phone) === null || _a === void 0 ? void 0 : _a.calling_code).concat((_b = bidder.user.cell_phone) === null || _b === void 0 ? void 0 : _b.number, " ").concat(bidder.user.email, " ").concat(bidder.paddle_number).toLowerCase();
                return combinedString.includes(searchValue.toLowerCase());
            });
            setFilteredUsers(filteredUsers_1);
        }
        else {
            setFilteredUsers(registeredUsers);
        }
    }, [searchValue]);
    (0, react_1.useEffect)(function () {
        setFilteredUsers(registeredUsers);
    }, [registeredUsers]);
    var getRegisteredUsers = function (client_id) {
        (0, bidders_for_client_1.bidders_for_client)({
            client_entity_id: client_id,
        }).then(function (response) {
            if (response.data.success) {
                setRegisteredUsers(response.data.content);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Failed to get bidders for client!", messages: [response.data.message] }));
            }
        });
    };
    var filteredData = filteredUsers.filter(function (user) {
        if (showVerified) {
            return user.is_verified == true;
        }
        if (showUnverified) {
            return user.is_verified == false;
        }
        if (showUnverifiedWithDocs) {
            return user.is_verified == false && user.has_docs == true;
        }
        return true;
    });
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 px-4 pt-4 w-full" },
            react_1.default.createElement(react_router_dom_1.Routes, null,
                react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement("div", { className: "flex flex-col space-y-4" },
                        react_1.default.createElement("div", { className: "flex flex-row" },
                            react_1.default.createElement("div", { className: "flex justify-start flex-1" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "4xlarge", label: "Bidders".concat((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient._id) ? " for ".concat(selectedClient.name) : ""), bold: true, textColor: "text-black" }))),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Register a Bidder", backgroundColor: "bg-green-600", size: "small", onClick: function () {
                                    if (selectedClient && selectedClient.entity_id) {
                                        dispatch((0, main_1.setContentModal)({
                                            title: "Register a Bidder",
                                            content: (react_1.default.createElement(register_bidder_1.RegisterBidderScreen, { refreshUsers: function () {
                                                    getRegisteredUsers(selectedClient.entity_id);
                                                } })),
                                        }));
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Select Client",
                                            messages: ["Please select a client before registering a bidder."],
                                        }));
                                    }
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete All Bidders", backgroundColor: "bg-red-500", size: "small", onClick: function () {
                                    if (selectedClient && selectedClient.entity_id) {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Delete All Bidders",
                                            buttons: "yesNo",
                                            messages: [
                                                "Are you sure you want to delete all bidders?",
                                                "Deleting all bidders will NOT delete the users. Users will stays intact.",
                                                "Deleting all bidders will require everyone to re-upload documents to be able to bid again.",
                                            ],
                                            onConfirm: function () {
                                                (0, delete_all_bidders_1.delete_all_bidders)({
                                                    client_entity_id: selectedClient.entity_id,
                                                }).then(function (response) {
                                                    if (response.data.success) {
                                                        getRegisteredUsers(selectedClient.entity_id);
                                                        dispatch((0, main_1.setMessageModal)(null));
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Failed to delete all bidders!", messages: [response.data.message] }));
                                                    }
                                                });
                                            },
                                        }));
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Select Client",
                                            messages: ["Please select a client before trying to delete all bidders."],
                                        }));
                                    }
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Unverify All Bidders", backgroundColor: "bg-orange-500", size: "small", onClick: function () {
                                    if (selectedClient && selectedClient.entity_id) {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Unverify All Bidders",
                                            buttons: "yesNo",
                                            messages: [
                                                "Are you sure you want to unverify all bidders?",
                                                "Unverifying all bidders will NOT require them to re-upload documents.",
                                            ],
                                            onConfirm: function () {
                                                (0, unverify_all_bidders_1.unverify_all_bidders)({
                                                    client_entity_id: selectedClient.entity_id,
                                                }).then(function (response) {
                                                    if (response.data.success) {
                                                        getRegisteredUsers(selectedClient.entity_id);
                                                        dispatch((0, main_1.setMessageModal)(null));
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Failed to unverify all bidders!", messages: [response.data.message] }));
                                                    }
                                                });
                                            },
                                        }));
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Select Client",
                                            messages: ["Please select a client before trying to delete all bidders."],
                                            onConfirm: function () {
                                                (0, delete_all_bidders_1.delete_all_bidders)({
                                                    client_entity_id: selectedClient.entity_id,
                                                }).then(function (response) {
                                                    if (response.data.success) {
                                                        getRegisteredUsers(selectedClient.entity_id);
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Failed to unverify all bidders!", messages: [response.data.message] }));
                                                    }
                                                });
                                            },
                                        }));
                                    }
                                } })),
                        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                            react_1.default.createElement("div", { className: "flex justify-between items-center" },
                                react_1.default.createElement("div", { className: "flex gap-4" },
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show Verified Bidders", value: showVerified, onChange: function (v) {
                                            setShowUnverified(false);
                                            setShowUnverifiedWithDocs(false);
                                            setShowVerified(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show Un-Verified Bidders", value: showUnverified, onChange: function (v) {
                                            setShowVerified(false);
                                            setShowUnverifiedWithDocs(false);
                                            setShowUnverified(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Show Un-Verified Bidders With Docs", value: showUnverifiedWithDocs, onChange: function (v) {
                                            setShowUnverified(false);
                                            setShowVerified(false);
                                            setShowUnverifiedWithDocs(v);
                                        } })),
                                react_1.default.createElement("div", { className: "" },
                                    "Records: ",
                                    filteredData.length),
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", size: "small", onChange: function (v) {
                                        setSearchValue(v);
                                    } })),
                            react_1.default.createElement(doshx_controls_web_1.TableControl
                            // onSearch={(searchVal) => {
                            //   //
                            // }}
                            , { 
                                // onSearch={(searchVal) => {
                                //   //
                                // }}
                                columns: [
                                    {
                                        label: "Name",
                                        displayKey: "",
                                        formatter: function (obj) {
                                            return obj.user.name;
                                        },
                                    },
                                    {
                                        label: "Surname",
                                        displayKey: "",
                                        formatter: function (obj) {
                                            return obj.user.surname;
                                        },
                                    },
                                    {
                                        label: "Phone Number",
                                        displayKey: "",
                                        formatter: function (obj) {
                                            var _a, _b;
                                            console.log("obj", obj);
                                            return "".concat((_a = obj.user.cell_phone) === null || _a === void 0 ? void 0 : _a.calling_code).concat((_b = obj.user.cell_phone) === null || _b === void 0 ? void 0 : _b.number);
                                        },
                                    },
                                    {
                                        label: "Email",
                                        displayKey: "",
                                        formatter: function (obj) {
                                            return obj.user.email;
                                        },
                                    },
                                    { label: "Paddle Number", displayKey: "paddle_number" },
                                    {
                                        label: "Date Registered",
                                        displayKey: "created_at",
                                        formatter: function (obj) {
                                            return doshx_controls_web_1.Utilities.formatDateTime(obj.created_at);
                                        },
                                    },
                                    {
                                        label: "Verified",
                                        displayKey: "is_verified",
                                        formatter: function (obj) {
                                            // console.log("obj", obj);
                                            return obj.is_verified ? "Yes" : "No";
                                        },
                                    },
                                    {
                                        label: "Has Docs?",
                                        displayKey: "has_docs",
                                        formatter: function (obj) {
                                            var _a;
                                            console.log("obj", obj);
                                            return ((_a = obj.has_docs) !== null && _a !== void 0 ? _a : false) ? "Yes" : "No";
                                        },
                                    },
                                ], data: filteredData, onRowClicked: function (row) {
                                    var _a;
                                    dispatch((0, scroll_1.setPreservedScroll)(__assign(__assign({}, preserved_scroll), (_a = {}, _a[pathname] = scrollRef.current.scrollTop, _a))));
                                    dispatch((0, bidder_1.setSelectedBidder)(row));
                                    navigate("".concat(pathname, "/details"));
                                }, 
                                // pagination={{
                                //   rowsPerPage: 10,
                                //   currentPage: 1,
                                //   onPageChange: (page) => {
                                //     // setCurrentPage(page);
                                //   },
                                //   onRowsPerPageChange: (rows) => {
                                //     // setRowsPerPage(rows);
                                //   },
                                // }}
                                colors: colors_1.tableColors, rowActions: [
                                    {
                                        label: "Delete",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Delete Bidder",
                                                messages: ["Are you sure you want to delete this bidder?"],
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, delete_bidder_1.delete_bidder)({
                                                        bidder_entity_id: row.entity_id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            getRegisteredUsers(selectedClient.entity_id);
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while deleting the bidder!", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                ] }))) }),
                react_1.default.createElement(react_router_dom_1.Route, { path: "/details", element: react_1.default.createElement(details_1.BidderDetailsScreen, { scrollRef: scrollRef, onSuccess: function () {
                            // initialize(0);
                            getRegisteredUsers(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
                        } }) })))));
};
exports.BiddersScreen = BiddersScreen;
