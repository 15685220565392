"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPreviousCurrentNext = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var manual_current_lot_1 = require("../../../../../api/calls/lot/v3/manual_current_lot");
var main_1 = require("../../../../../reducers/slices/main");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var manual_set_current_1 = require("../../../../../api/calls/lot/v3/manual_set_current");
var socket_1 = require("../../../../../socket");
var manual_lot_status_1 = require("../../../../../api/calls/lot/v3/manual_lot_status");
var AdminPreviousCurrentNext = function (props) {
    var _a, _b;
    var currentLotChanged = props.currentLotChanged;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)(null), pcn = _c[0], setPcn = _c[1];
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    (0, react_1.useEffect)(function () {
        if (mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id) {
            socket_1.socket.off("currentLot");
            socket_1.socket.on("currentLot", function (data) {
                getPCN();
            });
            getPCN();
        }
    }, [mainAuction === null || mainAuction === void 0 ? void 0 : mainAuction.entity_id]);
    function getPCN() {
        (0, manual_current_lot_1.manual_previous_current_next)({
            auction_entity_id: mainAuction.entity_id,
        }).then(function (response) {
            if (response.data.success) {
                setPcn(response.data.content);
                currentLotChanged(response.data.content.current);
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Error",
                    messages: [response.data.message],
                }));
            }
        });
    }
    function manualSetCurrent(lot_entity_id) {
        if (lot_entity_id) {
            (0, manual_set_current_1.manual_set_current)({
                auction_entity_id: mainAuction.entity_id,
                lot_entity_id: lot_entity_id,
            }).then(function (response) {
                if (response.data.success) {
                    //
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Error",
                        messages: [response.data.message],
                    }));
                }
            });
        }
    }
    function manualLotStatus(lot_entity_id, status) {
        (0, manual_lot_status_1.manual_lot_status)({
            entity_id: lot_entity_id,
            status: status,
        }).then(function (response) {
            if (response.data.success) {
                if (pcn.next) {
                    manualSetCurrent(pcn.next.entity_id);
                }
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Error",
                    messages: [response.data.message],
                }));
            }
        });
    }
    return pcn ? (react_1.default.createElement("div", { className: "grid grid-cols-7 border-4 border-bluer rounded-xl overflow-clip h-full" },
        react_1.default.createElement("div", { className: "flex flex-col col-span-2\n          ".concat(((_a = pcn.previous) === null || _a === void 0 ? void 0 : _a.entity_id) && "", "\n        "), onClick: function () {
                // manualSetCurrent(pcn.previous?.entity_id);
            } },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-bluer pb-1 text-white" }, "PREVIOUS"),
            pcn.previous ? ( //
            react_1.default.createElement("div", { className: "flex-1 p-2 flex flex-col items-center justify-center" },
                react_1.default.createElement("div", { className: "flex gap-1 items-center justify-center border-b" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                    react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.previous.lot_number)),
                react_1.default.createElement("div", { className: "text-lg font-bold" }, pcn.previous.title))) : (react_1.default.createElement("div", { className: "flex-1 flex items-center justify-center bg-red-300" }, "No Previous Lot"))),
        react_1.default.createElement("div", { className: "flex flex-col col-span-3" },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-bluer pb-1 text-white" }, "CURRENT"),
            pcn.current ? ( //
            react_1.default.createElement("div", { className: "flex-1 flex flex-col items-center justify-center\n              border-l-4 border-r-4 border-l-red-500 border-r-green-500 h-full\n            " },
                react_1.default.createElement("div", { className: "flex gap-2 py-2" },
                    react_1.default.createElement("div", { className: "flex gap-1 justify-center items-center" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                        react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.current.lot_number)),
                    react_1.default.createElement(react_1.default.Fragment, null, "-"),
                    react_1.default.createElement("div", { className: "text-lg font-bold" }, pcn.current.title)),
                react_1.default.createElement("div", { className: "w-full flex flex-row cursor-pointer h-10" },
                    react_1.default.createElement("div", { className: "flex-1 bg-green-500 px-4 flex items-center justify-center font-bold text-white hover:!bg-black \n                transition-colors", onClick: function () {
                            manualLotStatus(pcn.current.entity_id, "Sold");
                        } },
                        react_1.default.createElement(react_1.default.Fragment, null, "SOLD")),
                    react_1.default.createElement("div", { className: "flex-1 bg-bluish px-4 flex items-center justify-center  font-bold text-white hover:!bg-black\n                transition-colors", onClick: function () {
                            manualLotStatus(pcn.current.entity_id, "Passed");
                        } },
                        react_1.default.createElement(react_1.default.Fragment, null, "PASS"))))) : ("No Current Lot")),
        react_1.default.createElement("div", { className: "flex flex-col col-span-2\n          ".concat(((_b = pcn.next) === null || _b === void 0 ? void 0 : _b.entity_id) && "", "\n        "), onClick: function () {
                var _a;
                if ((_a = pcn.next) === null || _a === void 0 ? void 0 : _a.entity_id) {
                    // manualSetCurrent(pcn.next.entity_id);
                }
            } },
            react_1.default.createElement("div", { className: "font-bold text-xl text-center bg-bluer pb-1 text-white" }, "NEXT"),
            pcn.next ? ( //
            react_1.default.createElement("div", { className: "flex-1 p-2 flex flex-col items-center justify-center" },
                react_1.default.createElement("div", { className: "flex gap-1 items-center justify-center border-b" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag }),
                    react_1.default.createElement("span", { className: "font-bold text-md" }, pcn.next.lot_number)),
                react_1.default.createElement("div", { className: "text-lg font-bold" }, pcn.next.title))) : (react_1.default.createElement("div", { className: "flex-1 flex items-center justify-center bg-red-300" }, "No Next Lot"))))) : (react_1.default.createElement("div", { className: "grid grid-cols-3" }, "No PCN detected."));
};
exports.AdminPreviousCurrentNext = AdminPreviousCurrentNext;
