"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.admin_delete = void 0;
var api_1 = require("../../api");
var admin_delete = function (request) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/admin/delete",
        data: request,
    });
};
exports.admin_delete = admin_delete;
