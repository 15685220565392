"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisteredBiddersComponent = void 0;
var react_1 = __importStar(require("react"));
var functions_1 = require("../../../../constants/functions");
var bidders_for_client_1 = require("../../../../api/calls/bidder/v3/bidders_for_client");
var react_redux_1 = require("react-redux");
var RegisteredBiddersComponent = function () {
    var _a = (0, react_1.useState)([]), verifiedUsers = _a[0], setVerifiedUsers = _a[1];
    var _b = (0, react_1.useState)([]), registeredUsers = _b[0], setRegisteredUsers = _b[1];
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    (0, react_1.useEffect)(function () {
        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
            getRegisteredUsersForClient(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id);
        }
    }, [selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id]);
    var getRegisteredUsersForClient = function (client_id) {
        (0, bidders_for_client_1.bidders_for_client)({
            client_entity_id: client_id,
        }).then(function (response) {
            if (response.data.success) {
                var registeredUsers_1 = response.data.content;
                // Count the ones with is_verified = true
                var verifiedUsers_1 = registeredUsers_1.filter(function (user) { return user.is_verified === true; });
                setVerifiedUsers(verifiedUsers_1);
                setRegisteredUsers(registeredUsers_1);
            }
            else {
                console.log("response.data.message", response.data.message);
            }
        });
    };
    return (react_1.default.createElement("div", { className: "w-navigation-side flex flex-col" },
        react_1.default.createElement("div", { className: "bg-[#1C77F8] p-2 rounded-t-lg text-white text-center flex flex-row space-x-2" },
            react_1.default.createElement("div", null, "REGISTERED BIDDERS"),
            react_1.default.createElement("div", { className: "text-sm" },
                "(",
                verifiedUsers.length,
                " / ",
                registeredUsers.length,
                ")")),
        react_1.default.createElement("div", { className: "flex flex-col divide-y rounded-b-lg overflow-hidden shadow-lg" }, registeredUsers.map(function (registeredUser, index) {
            return (react_1.default.createElement("div", { key: "registered-user-".concat(index), className: "flex gap-4 justify-between" },
                react_1.default.createElement("div", { className: "bg-white p-2 hover:bg-gray-100 transition" },
                    (0, functions_1.capitalizeFirstLetter)(registeredUser.user.name),
                    " ",
                    (0, functions_1.capitalizeFirstLetter)(registeredUser.user.surname)),
                react_1.default.createElement("div", { className: "flex items-center pr-4" },
                    react_1.default.createElement("div", { className: "".concat(registeredUser.is_verified ? "bg-green-500" : "bg-red-500", " w-4 h-4 rounded-full") }))));
        }))));
};
exports.RegisteredBiddersComponent = RegisteredBiddersComponent;
