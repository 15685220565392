"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPlayNewBidSound = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    playNewBidSound: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "sound",
    initialState: initialState,
    reducers: {
        setPlayNewBidSound: function (state, action) {
            state.playNewBidSound = action.payload;
        },
    },
});
exports.setPlayNewBidSound = slice.actions.setPlayNewBidSound;
exports.default = slice.reducer;
