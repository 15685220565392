"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidderDetailsScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
// import { pdfjs, Document, Page } from "react-pdf";
// import moment from "moment";
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var constants_1 = require("../../../constants/constants");
var verification_1 = require("../../../api/calls/bidder/v3/verification");
var documents_1 = require("./documents");
var regenerate_paddle_number_1 = require("../../../api/calls/bidder/v3/regenerate_paddle_number");
var bidder_1 = require("../../../reducers/slices/bidder");
var colors_1 = require("../../../constants/colors");
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
var BidderDetailsScreen = function (props) {
    var _a;
    var onSuccess = props.onSuccess;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var mainAuction = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).mainAuction;
    var selectedBidder = (0, react_redux_1.useSelector)(function (state) { return state.bidderState; }).selectedBidder;
    var _b = (0, react_1.useState)(), numPages = _b[0], setNumPages = _b[1];
    var _c = (0, react_1.useState)(1), pageNumber = _c[0], setPageNumber = _c[1];
    (0, react_1.useEffect)(function () {
        if (!(selectedBidder === null || selectedBidder === void 0 ? void 0 : selectedBidder.user_entity_id)) {
            navigate("/console/bidders");
        }
    }, []);
    var isSupportedBrowserImage = function (url) {
        var extension = url.split(".").pop();
        console.log(extension);
        return constants_1.SUPPORTED_IMAGE_EXTENSIONS.includes(".".concat(extension === null || extension === void 0 ? void 0 : extension.toLowerCase().trim()));
    };
    var isPDF = function (url) {
        var extension = url.split(".").pop();
        return extension === "pdf";
    };
    return (react_1.default.createElement("div", { className: "flex flex-col space-y-4 px-4 pt-4 w-full" },
        react_1.default.createElement("div", { className: "text-2xl" },
            "Bidder - #", selectedBidder === null || selectedBidder === void 0 ? void 0 :
            selectedBidder.paddle_number),
        (selectedBidder === null || selectedBidder === void 0 ? void 0 : selectedBidder.user_entity_id) ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-row" },
                !selectedBidder.is_verified && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Verify This Bidder", backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Verify Bidder?",
                            messages: ["Are you sure that you want to mark this bidder as verified?"],
                            buttons: "yesNo",
                            onConfirm: function () {
                                (0, verification_1.bidder_verification)({
                                    entity_id: selectedBidder.entity_id,
                                    verified: true,
                                }).then(function (response) {
                                    console.log(response);
                                    if (response.data.success) {
                                        dispatch((0, main_1.setMessageModal)(null));
                                        // navigate("/console/bidders");
                                        dispatch((0, bidder_1.setSelectedBidder)(__assign(__assign({}, selectedBidder), { is_verified: true })));
                                        onSuccess();
                                    }
                                });
                            },
                        }));
                    } })),
                selectedBidder.is_verified && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Un-Verify This Bidder", backgroundColor: "bg-red-500", size: "small", onClick: function () {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Un-verify Bidder?",
                            messages: ["Are you sure that you want to mark this bidder as un-verified?"],
                            buttons: "yesNo",
                            onConfirm: function () {
                                (0, verification_1.bidder_verification)({
                                    entity_id: selectedBidder.entity_id,
                                    verified: false,
                                }).then(function (response) {
                                    console.log(response);
                                    if (response.data.success) {
                                        dispatch((0, main_1.setMessageModal)(null));
                                        // navigate("/console/bidders");
                                        dispatch((0, bidder_1.setSelectedBidder)(__assign(__assign({}, selectedBidder), { is_verified: false })));
                                        onSuccess();
                                    }
                                });
                            },
                        }));
                    } }))),
            react_1.default.createElement(doshx_controls_web_1.TabControl, { colors: colors_1.tabColors, tabs: [
                    {
                        title: "Details",
                        content: (react_1.default.createElement("div", { className: "flex-1 flex flex-col p-4" },
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Paddle Number:" })),
                                react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center" },
                                    react_1.default.createElement("span", null, selectedBidder.paddle_number),
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { size: "small", label: "Re-generate", textColor: "text-red-500", onClick: function () {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Regenerate Paddle Number?",
                                                messages: ["Are you sure that you want to regenerate the paddle number for this bidder?"],
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, regenerate_paddle_number_1.regenerate_paddle_number)({
                                                        bidder_entity_id: selectedBidder.entity_id,
                                                    }).then(function (response) {
                                                        console.log(response);
                                                        if (response.data.success) {
                                                            // dispatch selectedBidder & update the paddle object
                                                            dispatch((0, bidder_1.setSelectedBidder)(__assign(__assign({}, selectedBidder), { paddle_number: response.data.content.paddle_number })));
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Successfully Regenerated!",
                                                                messages: ["The bidder's paddle number has been changed to #".concat(response.data.content.paddle_number, " successfully.")],
                                                                buttons: "ok",
                                                                onConfirm: function () {
                                                                    dispatch((0, main_1.setMessageModal)(null));
                                                                    onSuccess();
                                                                },
                                                            }));
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Oops!",
                                                                messages: [
                                                                    "An error occurred while regenerating the paddle number.",
                                                                    response.message,
                                                                ],
                                                                buttons: "ok",
                                                                onConfirm: function () {
                                                                    dispatch((0, main_1.setMessageModal)(null));
                                                                },
                                                            }));
                                                        }
                                                    });
                                                },
                                            }));
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Name:" })),
                                react_1.default.createElement("div", null, selectedBidder.user.name)),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Surname:" })),
                                react_1.default.createElement("div", null, selectedBidder.user.surname)),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Contact Number:" })),
                                selectedBidder.user.cell_phone.calling_code,
                                selectedBidder.user.cell_phone.number),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email Address:" })),
                                react_1.default.createElement("div", null, selectedBidder.user.email)),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Address:" })),
                                react_1.default.createElement("div", null, selectedBidder.user.address)),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "w-52" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Identification Number:" })),
                                react_1.default.createElement("div", null, selectedBidder.user.id_number)),
                            react_1.default.createElement("div", { className: "flex flex-row" },
                                react_1.default.createElement("div", { className: "" },
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Is Verified?", value: (_a = selectedBidder.is_verified) !== null && _a !== void 0 ? _a : false }))))),
                    },
                    { title: "Documents", content: react_1.default.createElement(documents_1.BidderDocumentsTab, { bidder_entity_id: selectedBidder.entity_id }) },
                ] }))) : (react_1.default.createElement("div", null, "No bidder selected"))));
};
exports.BidderDetailsScreen = BidderDetailsScreen;
