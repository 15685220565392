"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtraDataControl = void 0;
var react_1 = __importStar(require("react"));
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
// import { ButtonControl } from "../button/button";
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
// import { TextInputControl } from "../textinput/textinput";
var ExtraDataControl = function (props) {
    var title = props.title, extra_data = props.extra_data, onChange = props.onChange, isLoading = props.isLoading;
    var _a = (0, react_1.useState)([]), selectedExtraData = _a[0], setSelectedExtraData = _a[1]; // { key: "", value: "" }
    var _b = (0, react_1.useState)([]), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        console.log("extra_data", extra_data);
        setSelectedExtraData(extra_data);
    }, [extra_data]);
    (0, react_1.useEffect)(function () {
        setLoading(isLoading);
    }, [isLoading]);
    return (react_1.default.createElement("div", { className: "flex flex-col space-y-2 p-4 bg-gray-200 rounded-xl w-80" },
        title && react_1.default.createElement("div", { className: "text-lg" }, title),
        loading ? ("Loading...") : (react_1.default.createElement("div", { className: "flex flex-col space-y-2" },
            react_1.default.createElement("div", { className: "flex space-x-2" },
                react_1.default.createElement("div", { className: "w-full" }, "Key"),
                react_1.default.createElement("div", { className: "w-full" }, "Value")),
            selectedExtraData.map(function (extraData, index) {
                return (react_1.default.createElement("div", { key: index, className: "flex items-center relative" },
                    react_1.default.createElement("div", { className: "flex-none w-full flex flex-row space-x-2" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: extraData.key, onChange: function (v) {
                                extraData.key = v;
                                setSelectedExtraData(__spreadArray([], selectedExtraData, true));
                                onChange(selectedExtraData);
                            } }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: extraData.value, onChange: function (v) {
                                extraData.value = v;
                                setSelectedExtraData(__spreadArray([], selectedExtraData, true));
                                onChange(selectedExtraData);
                            } })),
                    react_1.default.createElement("div", { className: "absolute -top-2 -right-2 flex-none \n                bg-bluer hover:bg-red-500 transition-all\n                  w-6 h-6 flex justify-center items-center rounded-full cursor-pointer", onClick: function () {
                            selectedExtraData.splice(index, 1);
                            setSelectedExtraData(__spreadArray([], selectedExtraData, true));
                            onChange(selectedExtraData);
                        } },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { className: "text-lg text-white", icon: pro_light_svg_icons_1.faTimes }))));
            }))),
        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: selectedExtraData.length == 0 ? "Add data" : "Add more data", onClick: function () {
                var _a, _b;
                var lastIndex = (_b = (_a = selectedExtraData[selectedExtraData.length - 1]) === null || _a === void 0 ? void 0 : _a.index) !== null && _b !== void 0 ? _b : 0;
                selectedExtraData.push({ key: "", value: null });
                setSelectedExtraData(__spreadArray([], selectedExtraData, true));
                onChange(selectedExtraData);
            } })));
};
exports.ExtraDataControl = ExtraDataControl;
