"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unverify_all_bidders = void 0;
var api_1 = require("../../../api");
var unverify_all_bidders = function (req) {
    return (0, api_1.apiPut)({
        endpoint: "v3/bidder/unverify_all_bidders",
        data: req,
    });
};
exports.unverify_all_bidders = unverify_all_bidders;
